.media {
  display: flex;
  align-items: flex-start;
}

.media-body {
  flex: 1;
}

.media-center {
  display: flex;
  align-items: center;
}
