@import "./src/assets/scss/app-variables";

.bell {
  &:hover {
    .bellButton {
      color: $color_primary;
    }
  }

  .bellIcon {
    position: relative;
    margin-right: 16px;
    font-size: $header-font-size-lg;

    @media (max-width: 768px) {
      margin-right: 0;
    }

    &Active::after {
      content: "";
      width: 12px;
      height: 12px;
      background-color: $color_decoration-1;
      border-radius: 100%;
      border: 2px solid $white;
      position: absolute;
      bottom: 14px;
      left: 14px;
    }
  }

  .bellButton {
    font-size: $header-font-size-sm;
    line-height: 1.2;
    color: $color_additional_darken-2;

    span {
      margin: auto 0;

      @media (max-width: 768px) {
        display: none;
      }
    }

    &Active {
      color: $color_primary;
    }
  }

  .bellContent {
    position: absolute;
    top: calc(100% + 20px);
    right: 0;
    width: 350px;
    background: $white;
    border-radius: 6px;
    overflow: hidden;
    box-shadow: $box-shadow;

    ul {
      margin: 0;
    }

    .bellItem {
      padding: 12px 10px;
      display: flex;
      min-height: 62px;
      justify-content: space-between;
      border-bottom: 1px solid $color_additional_lighten-1;

      p {
        font-size: $header-font-size-sm;
        color: $color_additional_darken-2;
        line-height: 1.2;
        max-width: 280px;
        margin: auto 0;
      }
    }

    .bellClear {
      display: flex;

      button {
        font-size: $header-font-size-sm;
        line-height: 1.2;
        color: $color_primary;
        background: transparent;
        margin: 12px auto;
      }
    }
  }
}
