/* ======= All Common Style Mention Here ====== */
html {
  font-size: $html-font-size;
}

@media (max-width: 1700px) {
  html {
    font-size: ($html-font-size - 1);
  }
}

@media (max-width: 1366px) {
  html {
    font-size: ($html-font-size - 1);
  }
}

@media (max-width: 1280px) {
  html {
    font-size: ($html-font-size - 1);
  }
}

@media (max-width: 991px) {
  html {
    font-size: ($html-font-size - 2);
  }
}

body, html {
  height: 100%;
}

html {
  font-family: $font-family-base;
  line-height: 1.15;
  text-size-adjust: 100%;
  text-size-adjust: 100%;
  -ms-overflow-style: scrollbar;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

body {
  font-family: $font-family-base;
  font-weight: $font-weight-normal;
  color: $body-color;
  background-color: white;
  position: relative;
  overflow: hidden;
  width: 100%;
}

[tabindex="-1"]:focus {
  outline: none;
}

ul, ol {
  padding: 0;
}

select,
button,
textarea,
input {
  vertical-align: baseline;
}

html,
body {
  &[dir="rtl"],
  &[dir="ltr"] {
    unicode-bidi: embed;
  }
}

bdo[dir="rtl"] {
  direction: rtl;
  unicode-bidi: bidi-override;
}

bdo[dir="ltr"] {
  direction: ltr;
  unicode-bidi: bidi-override;
}

h1, h2, h3, h4, h5, h6 {
  font-weight: $fw-semi-bold;
}

p {
  font-size: 14px;
}

a {
  transition: all 0.5s ease 0s;
  display: inline-block;
  text-decoration: none;
  cursor: pointer;

  &:hover, &:focus, &.active {
    color: inherit;
    text-decoration: none;
    outline: 0;
  }
}

*::selection {
  background-color: $primary;
  color: $light;
}

::placeholder { /* Firefox 19+ */
  color: $input-placeholder-color !important;
}

* {
  outline: none;

  &:hover, &:focus, &.active {
    outline: none;
  }
}

.pull-left {
  float: left;
}

.pull-right {
  float: right;
}

sup {
  top: -0.9em;
}

disabled {
  cursor: none;
}

.vr-super {
  vertical-align: super;
}

.pos-static {
  position: static !important;
}

.overlay-wrap {
  position: relative;
}

.overlay-content {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.overflow-hidden {
  overflow: hidden;
}

.border-rad-sm {
  border-radius: 5px;
}

address {
  span {
    display: block;
  }
}

.pos-center {
  &::before {
    content: "";
    display: inline-block;
    height: 100%;
    vertical-align: middle;
  }
}

.center-holder {
  display: inline-block;
  height: auto;
  padding: 1.25rem;
  vertical-align: middle;
  width: 90%;
}

.strike p,.strike h5 {
  text-decoration: line-through;
}

.row.row-eq-height {
  display: box;
  display: flex;
  display: flexbox;
  display: flex;
}

.switch {
  &.on {
    background-color: $success;
  }
}

.modal-open {
  overflow: visible !important;
}

.aqua-ripple {
  >div {
    transition: all 0.2s ease-in-out;

    &:hover {
      background-color: $aqua !important;
    }
  }
}

[class*="col-"] {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(100%, 1fr));
}

/* ======== Breadcrumb ========= */
.breadcrumb {
  background: none;
}

/* ======== Loader Scss ======== */
.rct-loader {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100% !important;

  .spring-spinner-rotator {
    border-top-color: $blue !important;
    border-right-color: $blue !important;
  }
}

.loader-overlay {
  position: absolute;
  background: rgba(255, 255, 255, 0.8);
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 99;
  align-items: center;
  overflow: hidden;
  margin: 0 auto;
}

.preloader-sidebar {
  background-color: rgba(0, 0, 0, 0.2);
}

.preload-widget {
  background-color: $white;
  box-shadow: $block-shadow;
}

.preload-header {
  background-color: $white;
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
}

.fixed-plugin {
  .agency-bg {
    display: none;
  }
}

/* ======== Responsive ========= */
@media (max-width: 1199px) and (min-width: 768px) {
  .d-sm-full {
    max-width: 100% !important;
    flex-basis: 100% !important;
  }

  .d-sm-half-block {
    max-width: 50% !important;
    flex-basis: 50% !important;
  }
}

@media (max-width: 991px) and (min-width: 576px) {
  .d-xs-full {
    max-width: 100% !important;
    flex-basis: 100% !important;
  }

  .d-xs-half-block {
    max-width: 50% !important;
    flex-basis: 50% !important;
  }
}

@media (max-width: 575px) and (min-width: 450px) {
  .d-xxs-full {
    max-width: 100% !important;
    flex-basis: 100% !important;
  }

  .d-xxs-half-block {
    max-width: 50% !important;
    flex-basis: 50% !important;
  }
}

@media (max-width: 450px) {
  .d-xs-none {
    display: none;
  }
}

@media (max-width: 575px) and (min-width: 320px) {
  .display-n {
    display: none !important;
  }
}
