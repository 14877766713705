.expiry-label {
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
}

.expiry-action-buttons {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}
