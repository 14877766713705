.disabled-checkbox {
  color: #cecece !important;
}

.checked-option {
  color: $edge_orange !important;
}

.selected-button {
  background-color: $grey_label !important;
}

.leading-button {
  background-color: $edge_orange !important;
}

.checked-right-answer {
  background-color: $green !important;
}

.checked-wrong-answer {
  background-color: $edge_red !important;
}

.survey-average-info {
  border: 1px solid $grey_label;
  border-radius: 5px;
  padding: 5px 5px 5px 15px;
  margin-bottom: 10px;
}

.percentage-label {
  grid-area: 1 / 1 / 2 / 2;
  border-radius: 5px;
}

.survey-text {
  grid-area: 1 / 1 / 2 / 2;
  padding: 5px 10px;
  display: flex;
}
