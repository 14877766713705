@import "./src/assets/scss/app-variables";

/* ========= Customized EdgeWebapp Style ========= */

// * {
//  -webkit-box-shadow: none;
//  -moz-box-shadow:    none;
//  box-shadow: none !important;
//  text-shadow: none !important;
// }
// common
.flex-grow {
  flex-grow: 1;
}

.text-align-right { text-align: right; }
.text-align-left { text-align: left; }

.flex-center-hv {
  display: flex;
  justify-content: center;
  align-items: center;
}

.flex-left-h {
  display: flex;
  justify-content: left;
}

.flex-center-h {
  display: flex;
  justify-content: center;
}

.flex-center-v {
  display: flex;
  align-items: center;
}

.vertical-align-bottom {
  vertical-align: text-bottom;
}

.post-item {
  &.no-background-hover:hover {
    background: none;
  }
}

.upload-file-btn {
  box-sizing: border-box;
  border: 1px solid transparent;
  border-radius: 4px;
  padding: 8px 16px;
  min-width: 64px;
  min-height: 36px;
  box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.12);
  font-size: 0.875rem;
  color: #fff;
  background-color: #5d92f4;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  line-height: 1.5;
  letter-spacing: 0.02857em;
  text-transform: capitalize;
  cursor: pointer;
  outline: none;

  &:hover {
    background-color: rgb(65, 102, 170);
  }
}

.custom-mb-1 {
  margin-bottom: 1px;
}

.double-scale {
  transform: scale(2);
  transform: scale(2);
  transform: scale(2);
}

.break-word {
  word-wrap: break-word;
}

.border-b-gray {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}

// stream page header - for firstFilter and secondFilter dropdowns
// overwrites .app-selectbox from _forms.scss
.app-selectbox.without-before {
  background-color: $block-bg;

  &::before {
    display: none;
  }
}

// stream page header - for icons used to display cards as list or grid
// added span to .rct-filter>ul>li from _ecommerce.scss
.rct-filter > ul > li > span {
  cursor: pointer;

  &.active {
    color: $blue;
  }
}

.black-color {
  color: rgba(0, 0, 0, 0.87);
}

.black-alpha-50 {
  color: #7f7f7f;
}

.grey-color {
  color: $gray-600;
}

.margin-bottom-20 {
  margin-bottom: 1.25rem;
}

// my account page
.crt-month-points {
  color: $body-color;
}

.p-24 {
  padding: 24px;
}

.divider-left {
  border-left: 1px solid rgba(0, 0, 0, 0.12);
}

.divider-bottom {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}

.grayed-out {
  color: rgba(0, 0, 0, 0.5);
}

.transaction-link-wrapper {
  line-height: 1.35;

  a {
    color: rgb(0, 208, 20);

    &:hover, &:focus, &.active {
      color: inherit;
      text-decoration: none;
      outline: 0;
    }
  }
}

.page-scroll {
  overflow-y: auto;
  height: 100%;
  overflow-x: hidden;
}

.min-h-90 {
  height: 90%;
}

.btn-without-style {
  background: none;
  border: none;
  cursor: pointer;
  width: fit-content;
}

// leaderboard page
.height-40 {
  height: 40px;
}

.cursor-pointer {
  cursor: pointer;
}

.background-green {
  background-color: $green !important;
}

.background-blue {
  background-color: $color-primary !important;
}

.colored-bg {
  background-color: $color_main_bg;
}

.avatar-bg {
  background-color: $color_additional;
}

.color-green {
  color: $green;
}

.color-warning {
  color: $edge_orange;
}

.color-error {
  color: #ff3739;
}

a.color-green:hover {
  color: $green !important;
}

// Sidebar
.user-info-name {
  display: inline-block;
  width: 100%;
  vertical-align: middle;
}

// remove arrow right symbol at the end of nav item
.rct-sidebar {
  .rct-sidebar-nav {
    .rct-mainMenu {
      &.custom-rct-mainMenu {
        .upload-products-sidebar {
          a {
            background-color: #373752;
            border-radius: 5px;
          }
        }

        >li {
          &:hover {
            background: transparent;
          }

          padding: 0 !important;
          display: block;

          a {
            display: flex;
            align-items: center;
            margin: 1rem 1.5rem;
            min-height: 2.5rem;
          }

          &.no-right-arrow.list-item {
            &::after {
              display: none;
            }
          }
        }
      }
    }
  }
}

// ModalCurrentMonthPoints
.modal-points-btn {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 10px;
  border: none;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  background-color: transparent;
  color: $green;
  cursor: pointer;
  transition: color .17s ease-in-out, background-color .17s ease-in-out;

  &:hover {
    background-color: #efefef;
    color: $white;

    .points-amount span {
      color: $white;
    }
  }

  &.active {
    background-color: $green;
    color: $white;

    .points-amount span {
      color: $white;
    }
  }
}

.points-amount {
  color: $body-color;

  span:first-child {
    font-size: 12px;
    margin-top: 4px;
  }
}

.avatar-bounty-description {
  background-color: #fff !important;
  color: $secondary !important;
  border: 1px solid $secondary;
  margin-right: 15px;
}

.relative {
  position: relative;
}

.product-image {
  height: 250px;
  width: fit-content;
}

.product-price {
  padding-right: 10px;
  font-weight: 700;
}

.free-label {
  transform: rotate(45deg);
  background: $rss;
  color: white;
  width: 100px;
  position: absolute;
  top: 8px;
  right: -33px;
}

.line-through {
  text-decoration: line-through;
  padding-right: 10px;
}

.chat-wrapper {
  border-top: 1px solid rgba(0, 0, 0, 0.12);

  .chat-main-body {
    .chat-body {
      .chat-bubble {
        &.info-message {
          display: block;
        }
      }
    }
  }
}

.single-grid-list {
  flex-wrap: nowrap !important;

  // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
  transform: translateZ(0);
}

.disabled {
  opacity: 0.4;
}

.font-500 {
  font-weight: 500;
}

.font-400 {
  font-weight: 400;
}

.font-700 {
  font-weight: 700;
}

.no-background-color {
  background-color: transparent !important;
}

.app-container {
  border-right: 1px solid $border-color;
  height: 100%;
  width: 100%;
  max-width: 100%;

  //min-height: calc(100vh - 10px);
}

.logout-button {
  font-size: 0.875rem;
  margin-left: 20px;
  padding: 15px;
}

.full-height {
  .video-react.video-react-fluid,
  .video-react.video-react-16-9,
  .video-react.video-react-4-3 {
    height: 100%;
  }

  height: 100%;
}

.profile-banner {
  display: flex;
  justify-content: center;
  max-width: 100%;
  height: 23em;
  background: black;

  img {
    display: block;
    object-fit: contain;
    width: 100%;
    height: 100%;
  }
}

.date-dot {
  display: inline-block;
  width: 3px;
  height: 3px;
  border-radius: 50%;
  background: black;
  margin: 3px 5px;
}

.mb-100 {
  margin-bottom: 100px;
}

.font-12 {
  font-size: 0.8rem;
}

.font-15 {
  font-size: 1rem !important;
}

.font-16 {
  font-size: 1.1rem;
}

.font-18 {
  font-size: 1.2rem;
}

.font-21 {
  font-size: 1.4rem;
}

.font-28 {
  font-size: 1.867rem;
}

.font-36 {
  font-size: 2.4rem;
}

.font-50 {
  font-size: 3.33rem;
}

.align-items-center {
  align-items: center;
}

.text-underline {
  text-decoration: underline;
}

.color-green {
  color: $green;
}

.badge-custom-chat {
  height: 0.75rem;
  width: 0.75rem;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
}

.reply-to-description {
  border: 1px solid $border-color;
  border-radius: 5px;
  padding: 0.5rem;
}

.upload-cards {
  border: 1px solid $gray-700;
  background-color: transparent !important;
  box-shadow: none !important;

  .upload-products-btn {
    color: white;
    text-align: center;
    width: 100%;
    height: 40px;
    padding: 8px;
    border-radius: 5px;
    cursor: pointer;
  }
}

// LAYOUT - need for product upload page
.rct-page,
.rct-app-content,
.rct-page-content,
.ais-InstantSearch__root {
  min-height: calc(100vh - 90px);
}

.new-bg {
  background-color: $color_primary_lighten;
}

.MuiTableHead-root {
  .MuiTableCell-root {
    white-space: nowrap;
  }
}

////////

.zone-circle {
  position: absolute;
  width: 5rem;
  height: 5rem;
  border-radius: 40px;
  border: 2px solid #00d014;
  left: 1.4rem;
  bottom: 0.8rem;

  @media (max-width: 768px) {
    left: 2.2rem;
    bottom: 1.5rem;
  }
}

.stah-bk-image {
  background-image: url("../img/general/merchant-landing.jpg");
  background-size: cover;
  background-repeat: no-repeat;
}

.info-container {
  background: #221f1f;
  padding: 20px;
  border-radius: 10px;
  color: white;
  opacity: 0.8;
}

@media (min-width: 1200px) {
  .logged-container.container {
    max-width: 900px;
  }
}

.fab-button {
  background-color: transparent !important;
  height: 1px !important;
  right: 8%;
  z-index: 1000;
}

.pointer {
  cursor: pointer;
}

.image-post {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  height: inherit;
  border-radius: 10px;
}

.height-inherit {
  height: inherit;
}

.m-0-auto {
  margin: 0 auto;
}

.user-profile-page {
  max-width: 100%;

  // height: -webkit-fill-available;
}

.background-fcwiltz {
  background-color: #e52020;
}

.background-black {
  background-color: $color_black_60op;
}

.background-blue {
  background-color: $blue;
}

//  For the search input from header
.ais-SearchBox-form input {
  border: 1px solid rgba(0, 0, 0, 0.12);
}

.pre-wrap {
  white-space: pre-wrap;
}

.markdown-pre {
  font-family: $font-family-base;
  font-weight: $font-weight-normal;
  color: $black;
}

.markdown-wrapper {
  word-break: break-word;

  p, a, pre, span {
    font-size: 14px;
  }

  .post-urls, .disabled-link {
    text-decoration: underline;

    &:hover, &:focus {
      color: $green;
    }
  }
}

.product-image-placeholder {
  background-image: url("../img/general/image_placeholder.png");
}

body {
  overflow: auto;
  word-break: break-word;
}

.app-header {
  position: sticky;
  top: 0;
  z-index: 111;
}

.custom-sidebar {
  position: fixed;
  overflow-y: auto;
  width: 240px;
}

@media (max-width: 767px) {
  .hidden-sm {
    display: none;
  }
}

@media (min-width: 1200px) {
  .col-xl-sidebar {
    flex: 0 0 28%;
    max-width: 28%;
  }

  .col-xl-content {
    flex: 0 0 72%;
    max-width: 72%;
  }
}

.list-style-none {
  list-style: none;
}

.no-text-decoration {
  text-decoration: none;
}

.panel-tabs {
  font-size: 1rem !important;
  font-family: inherit !important;
}

.full-width {
  width: 100%;
}

.full-width-i {
  width: 100% !important;
}

.no-padding {
  padding: 0 !important;
}

.no-text-transform {
  text-transform: none !important;
}

.bounty-content {
  @include media-breakpoint-up(md) {
    width: 760px;
  }

  width: 100%;
}

a.item-active {
  color: $color_primary !important;
}

.css-1elwnq4-MuiPaper-root-MuiAccordion-root.Mui-expanded {
  margin: 0;
}
.css-o4b71y-MuiAccordionSummary-content.Mui-expanded {
  margin: 0;
}

.audit-col {
  min-width: 100px;
  margin: auto 0;

  &:first-of-type {
    min-width: 120px;
  }
}
