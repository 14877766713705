@import "./src/assets/scss/app-variables";

.chat {
  position: relative;

  &:hover {
    .chatButton {
      color: $color_primary;
    }
  }

  .chatIcon {
    position: relative;
    margin-right: 16px;
    font-size: $header-font-size-lg;

    @media (max-width: 768px) {
      margin-right: 0;
    }
  }

  .chatButton {
    font-size: $header-font-size-sm;
    line-height: 1.2;
    color: $color_additional_darken-2;

    span {
      margin: auto 0;

      @media (max-width: 768px) {
        display: none;
      }
    }
  }
}
