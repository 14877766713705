/* stylelint-disable */
@import "bootstrap/variables";

/* ====== All Custom Variable Introduce Here ====== */
$light-yellow: #fff59d ;
$twitter: #52aeff;
$facebook: #3b5998;
$google: #f13a30;
$pinterest: #d32f2f;
$instagram: #8d6e63;
$rss: #ff9800;
$tumblr: #607d8b;
$linkedin: #4875b4;
$dribbble: #f06292;
$youtube: #d50000;
$github: #424242;
$skype: #4fc3f7;
$font-size-s: 12px;
$font-size-m: 14px;
$font-size-l: 16px;
$font-size-xl: 24px;
$font-weight-heading: 500;
$font-weight-base: 400;
$colors: () !default;
$colors: map-merge(
  (
    "light-yellow":       $light-yellow,
    "twitter":            $twitter,
    "facebook":           $facebook,
    "google":             $google,
    "pinterest":          $pinterest,
    "instagram":          $instagram,
    "rss":                $rss,
    "tumblr":             $tumblr,
    "linkedin":           $linkedin,
    "dribbble":           $dribbble,
    "youtube":            $youtube,
    "github":             $github,
    "skype":              $skype,
  ),
  $colors
);
$light-yellow: $light-yellow;
$twitter: $twitter;
$facebook: $facebook;
$google: $google;
$pinterest: $pinterest;
$instagram: $instagram;
$rss: $rss;
$tumblr: $tumblr;
$linkedin: $linkedin;
$dribbble: $dribbble;
$youtube: $youtube;
$github: $github;
$skype: $skype;
$theme-colors: () !default;
$theme-colors: map-merge(
  (
    "light-yellow":    $light-yellow,
    "twitter":         $twitter,
    "facebook":        $facebook,
    "google":          $google,
    "pinterest":       $pinterest,
    "instagram":       $instagram,
    "rss":             $rss,
    "tumblr":          $tumblr,
    "linkedin":        $linkedin,
    "dribbble":        $dribbble,
    "youtube":         $youtube,
    "github":          $github,
    "skype":           $skype
  ),
  $theme-colors
);

// Common elements
$box-shadow: 0 4px 16px rgba(103, 113, 121, 0.15);

// html font
$html-font-size: 16px;
$header-font-size-sm: 14px;
$header-font-size-lg: 24px;

// Block Settings
$block-bg: $white;
$block-border-radius: 5px;
$block-border: none;
$block-spacing: 1.25rem 1.25rem; //20px 20px;
$block-shadow: 0 1px 15px 1px rgba(69, 65, 78, 0.08);
$block-hover-shadow: 0 1px 15px 1px rgba(69, 65, 78, 0.08);
$block-margin-bottom: $grid-gutter-width; //24px
$block-transition: all 0.4s ease-in-out 0s;
$block-title-color: $gray-900;
$block-title-spacing: 0.9375rem 1.25rem; //15px 20px;
$block-title-font-size: 1rem; //16px
$block-title-font-weight: 500;
$block-title-transform: capitalize;
$block-contextual-color: $gray-600;
$block-contextual-font-size: 1rem;
$block-footer-border-width: 1px;
$block-footer-border-color: $gray-300;

//Breadcrumb Variable
$breadcrumb-font-size: 1.875rem; //30px;
$breadcrumb-font-color: $gray-900;

// Dark theme
$dark-bg: #2c3644;
$dark-bg-color: #fff;
$dark-bg-container: #303030;
$dark-border-color: rgba(0, 0, 0, 0.1);
$dark-placeholder: #303030;
$dark-secondary-bg: #434f5a;

// Input Fields
$input-height: 36px;
$input-lg-height: 52px;
$input-radius: 40px;
$v2_input-height: 44px;

// Gradient Theme Color
$sidebar-dark-gradient: linear-gradient(0deg, #464d69 0%, rgba(70, 77, 105, 1) 100%);
$sidebar-dark: #2e3344;
$overlay-light: rgb(255, 255, 255);
$gradient-primary: linear-gradient(180deg, #5d92f4 0%, #09203f 100%); // Primary
$gradient-purple: linear-gradient(0deg, #f53844 0%, #42378f 100%); // Purple
$gradient-success: linear-gradient(180deg, #00d0bd 0%, #007adf 100%); // Success
$gradient-warning: linear-gradient(0deg, #b02e0c 0%, #ffb70f 100%); // warning
$gradient-danger: linear-gradient(0deg, #b02e0c 0%, #ffb70f 100%);// danger

// Navbar Varibale
$navbar-height: 4rem; //80px;
$navbar-bg: $white;
$hamburger-icon-color: $gray-600;
$navbar-icon-height: 35px;
$navbar-icon-width: 35px;
$navbar-link-color: $white;

// Footer Variable
$footer-height: 50px;
$footer-bg: $navbar-bg;
$footer-box-shadow: none;

// Square Box
$square-font-size: 0.5; // 50% relative to the container
$square-box: (10, 15, 20, 30, 40, 50, 60, 70, 80, 100, 120);

// Sidebar Variable
$sidebar-width: 16.25rem; //280px;
$sidebar-box-shadow: none;
$sidebar-link-fontsize: 1rem; //14px
$sidebar-link-font-weight: 400;
$sidebar-link-font-color: $gray-900;
$sidebar-link-transform: capitalize;
$sidebar-link-spacing: 0.9375rem 2rem; //15px 40px;

$sidebar-icon-font-size: 1rem; //14px;

$sidebar-hover-bg: rgba(0, 0, 0, 0.22);
$sidebar-hover-color: $white;
$sidebar-submenu-font-size: 1rem; //14px
$sidebar-submenu-font-color: $gray-900;
$sidebar-submenu-spacing: 0.9375rem 2.5rem 0.9375rem 4.375rem;

// Mini Sidebar Variable
$mini-sidebar-width: 85px;

// Horizontal Menu sidebar Variable
$hoz-menu-bg: #fff;
$hoz-nav-link-color: #464d69;
$hoz-nav-font-size: 0.875rem; // 14px
$hoz-nav-link-spacing: 0.9375rem 1.563rem 0.9375rem 1.563rem; // 15px 30px;
$hoz-nav-link-hover-bg: #fafafa;
$hoz-menu-height: 3.125rem; // 50px;
$hoz-sub-menu-width: 15rem;
$hoz-box-shadow: 0 2px 10px 0 rgba(204, 204, 204, 0.5);
$hoz-sub-menu-box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.09);

// Social Link Variable
$social-icon-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.19);

// Weather Widget Variables
$weather-bg: url("../img/general/bkg-image-fallback.jpg");

// boxed layout
$boxed-bg: url("../img/general/bkg-image-fallback.jpg");
$dark-layout-bg: url("../img/general/bkg-image-fallback.jpg");

// Table Variable
$table-tbody-font-size: 14px;
$table-tbody-font-weight: 300;

//Quick link
$dropdown-link-shadow: 0 3px 2px 0 rgba(0, 0, 0, 0.02);
$base-light: #fafafa;

//Font Weight
$fw-semi-bold: 500;
$fw-bold: 700;
$fw-light: 300;
$fw-normal: 400;

//Custom Colors
$aqua: #e1ebff;
$green: #05c043;
$color_black_60op: #1f1f1f;
$color_black_20op: #2d2d2d;
$edge_dark_grey: #6000;
$edge_orange: #ffa800;
$edge_red: #ff4c4c;
$grey_label: #cecece;
$edge_blue: #1083ce;

// Updated Colors

// -- Basic
// TODO: use lighten/darken mixins (should be designed first)
$color_primary: #0078f2;
$color_primary_darken: #005ebd;
$color_primary_lighten: #f0f7ff;
$color_secondary: #0a1578;
$color_secondary_darken: #060c47;
$color_secondary_lighten: #f1f2fe;
$color_decoration-1: #f63d74;
$color_decoration-1_darken: #d93969;
$color_decoration-1_lighten: #fef0f5;
$color_decoration-2: #ff9457;
$color_decoration-2_darken: #e7844b;
$color_decoration-2_lighten: #fff5f0;
$color_decoration-3: #f6d86e;
$color_decoration-3_darken: #ebc94f;
$color_decoration-3_lighten: #fefbf1;
$color_decoration-4: #46d7c2;
$color_decoration-4_darken: #2ac1ab;
$color_decoration-4_lighten: #f2fcfb;
$color_dark: #222;
$color_light: #fff;
$color_additional: #c3cbd2;
$color_additional_darken-1: #a3adb5;
$color_additional_darken-2: #677179;
$color_additional_lighten-1: #e7e8ea;
$color_additional_lighten-2: #f9f9f9;
$color_accent: #f0f7ff;
$color_main_bg: #f4f6fa;

// -- Specific
$color_text-on-dark: $color_light;
