@import "./src/assets/scss/app-variables";

.profile {
  position: relative;
  display: flex;

  &:hover {
    .profileItem {
      &Icon,
      &Title {
        color: $color_primary;
      }
    }
  }

  .profileItem {
    &Avatar,
    &Icon,
    &Title {
      margin: auto 0 auto 10px;
      color: $color_additional_darken-2;
      font-size: $header-font-size-sm;
    }

    &Title {
      @media (max-width: 768px) {
        display: none;
      }
    }

    &Avatar {
      width: 35px;
      height: 35px;

      @media (max-width: 768px) {
        margin: auto 0;
      }
    }

    &Active {
      color: $color_primary;

      ~ .profileItemIcon {
        color: $color_primary;
      }
    }
  }

  .profileContent {
    position: absolute;
    top: calc(100% + 20px);
    right: 0;
    width: 260px;
    background: $white;
    border-radius: 6px;
    overflow: hidden;
    box-shadow: $box-shadow;

    .profileContentItem {
      padding: 12px 10px;
      min-height: 50px;
      cursor: pointer;
      color: $color_additional_darken-2;

      i {
        font-size: $header-font-size-lg;
      }

      span {
        font-size: $header-font-size-sm;
        margin-left: 10px;
      }
    }

    ul {
      margin: 0;
      padding: 0;
    }
  }
}
