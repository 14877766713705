.card-section {
  @extend .card-body;

  display: flex;
  flex-direction: row;
  width: 100%;
  padding-bottom: 0.5rem;

  h3 {
    margin-bottom: 0.2rem;
  }
}

.logo-img-container {
  width: 15%;
  min-width: 55px;

  img {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    margin-right: 0.5rem;
  }
}

.card-section-details {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.product-card-img {
  max-height: 90px;
  min-height: 60px;
  border-radius: 0.3rem;
}
