@import "./src/assets/scss/app-variables";

.imageWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}

.navItem {
  padding: 0;
  position: relative;
  z-index: 3;

  .fakeLink,
  a {
    padding: 10px 20px;
    display: flex;
    flex-shrink: 0;
    width: 100%;
    color: $color_additional_darken-2;
    position: relative;
    z-index: 3;

    &:hover,
    &:active {
      color: $color_primary;
    }
  }
}

.navItemWrapper {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
}
