* {
  scrollbar-width: thin;
  scrollbar-color: #ccc #fbfbfb;
  scroll-behavior: smooth;
}

*::-webkit-scrollbar {
  height: 6px;
  width: 8px;
}

*::-webkit-scrollbar-track {
  background: #fbfbfb;
  border-radius: 4px;
}

*::-webkit-scrollbar-thumb {
  background-color: #ccc;
  border-radius: 4px;
}
