@import "./icon-font-variables";
@import "./material_icon_font";

@font-face {
  font-family: "#{$icomoon-font-family}";
  src: url("#{$icomoon-font-path}/#{$icomoon-font-family}.eot?bijl3u");
  src:
    url("#{$icomoon-font-path}/#{$icomoon-font-family}.eot?bijl3u#iefix") format("embedded-opentype"),
    url("#{$icomoon-font-path}/#{$icomoon-font-family}.ttf?bijl3u") format("truetype"),
    url("#{$icomoon-font-path}/#{$icomoon-font-family}.woff?bijl3u") format("woff"),
    url("#{$icomoon-font-path}/#{$icomoon-font-family}.svg?bijl3u##{$icomoon-font-family}") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "#{$icomoon-font-family}" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-user {
  &::before {
    content: $icon-user;
  }
}

.icon-d-cube {
  &::before {
    content: $icon-d-cube;
  }
}

.icon-full {
  &::before {
    content: $icon-full;
  }
}

.icon-user1 {
  &::before {
    content: $icon-user1;
  }
}

.icon-support {
  &::before {
    content: $icon-support;
  }
}

.icon-activity {
  &::before {
    content: $icon-activity;
  }
}

.icon-activity-1 {
  &::before {
    content: $icon-activity-1;
  }
}

.icon-add {
  &::before {
    content: $icon-add;
  }
}

.icon-add-circle {
  &::before {
    content: $icon-add-circle;
  }
}

.icon-add-square {
  &::before {
    content: $icon-add-square;
  }
}

.icon-add-user {
  &::before {
    content: $icon-add-user;
  }
}

.icon-alarm {
  &::before {
    content: $icon-alarm;
  }
}

.icon-apple-negative {
  &::before {
    content: $icon-apple-negative;
  }
}

.icon-archive {
  &::before {
    content: $icon-archive;
  }
}

.icon-archive-1 {
  &::before {
    content: $icon-archive-1;
  }
}

.icon-archive-add {
  &::before {
    content: $icon-archive-add;
  }
}

.icon-archive-book {
  &::before {
    content: $icon-archive-book;
  }
}

.icon-archive-minus {
  &::before {
    content: $icon-archive-minus;
  }
}

.icon-archive-slash {
  &::before {
    content: $icon-archive-slash;
  }
}

.icon-archive-tick {
  &::before {
    content: $icon-archive-tick;
  }
}

.icon-arrange-circle {
  &::before {
    content: $icon-arrange-circle;
  }
}

.icon-arrange-circle-2 {
  &::before {
    content: $icon-arrange-circle-2;
  }
}

.icon-arrange-square {
  &::before {
    content: $icon-arrange-square;
  }
}

.icon-arrange-square-2 {
  &::before {
    content: $icon-arrange-square-2;
  }
}

.icon-arrow {
  &::before {
    content: $icon-arrow;
  }
}

.icon-arrow-2 {
  &::before {
    content: $icon-arrow-2;
  }
}

.icon-arrow-3 {
  &::before {
    content: $icon-arrow-3;
  }
}

.icon-arrow-bottom {
  &::before {
    content: $icon-arrow-bottom;
  }
}

.icon-arrow-circle-down {
  &::before {
    content: $icon-arrow-circle-down;
  }
}

.icon-arrow-circle-left {
  &::before {
    content: $icon-arrow-circle-left;
  }
}

.icon-arrow-circle-right {
  &::before {
    content: $icon-arrow-circle-right;
  }
}

.icon-arrow-circle-up {
  &::before {
    content: $icon-arrow-circle-up;
  }
}

.icon-arrow-down {
  &::before {
    content: $icon-arrow-down;
  }
}

.icon-arrow-down-1 {
  &::before {
    content: $icon-arrow-down-1;
  }
}

.icon-arrow-down-2 {
  &::before {
    content: $icon-arrow-down-2;
  }
}

.icon-arrow-left {
  &::before {
    content: $icon-arrow-left;
  }
}

.icon-arrow-left-1 {
  &::before {
    content: $icon-arrow-left-1;
  }
}

.icon-arrow-left-2 {
  &::before {
    content: $icon-arrow-left-2;
  }
}

.icon-arrow-left-3 {
  &::before {
    content: $icon-arrow-left-3;
  }
}

.icon-arrow-right {
  &::before {
    content: $icon-arrow-right;
  }
}

.icon-arrow-right-1 {
  &::before {
    content: $icon-arrow-right-1;
  }
}

.icon-arrow-right-2 {
  &::before {
    content: $icon-arrow-right-2;
  }
}

.icon-arrow-right-3 {
  &::before {
    content: $icon-arrow-right-3;
  }
}

.icon-arrow-right-4 {
  &::before {
    content: $icon-arrow-right-4;
  }
}

.icon-arrow-square {
  &::before {
    content: $icon-arrow-square;
  }
}

.icon-arrow-square-down {
  &::before {
    content: $icon-arrow-square-down;
  }
}

.icon-arrow-square-left {
  &::before {
    content: $icon-arrow-square-left;
  }
}

.icon-arrow-square-up {
  &::before {
    content: $icon-arrow-square-up;
  }
}

.icon-arrow-swap {
  &::before {
    content: $icon-arrow-swap;
  }
}

.icon-arrow-swap-horizontal {
  &::before {
    content: $icon-arrow-swap-horizontal;
  }
}

.icon-arrow-up {
  &::before {
    content: $icon-arrow-up;
  }
}

.icon-arrow-up-1 {
  &::before {
    content: $icon-arrow-up-1;
  }
}

.icon-arrow-up-2 {
  &::before {
    content: $icon-arrow-up-2;
  }
}

.icon-arrow-up-3 {
  &::before {
    content: $icon-arrow-up-3;
  }
}

.icon-autobrightness {
  &::before {
    content: $icon-autobrightness;
  }
}

.icon-award {
  &::before {
    content: $icon-award;
  }
}

.icon-back-square {
  &::before {
    content: $icon-back-square;
  }
}

.icon-bag {
  &::before {
    content: $icon-bag;
  }
}

.icon-bag-2 {
  &::before {
    content: $icon-bag-2;
  }
}

.icon-battery-charging {
  &::before {
    content: $icon-battery-charging;
  }
}

.icon-battery-disable {
  &::before {
    content: $icon-battery-disable;
  }
}

.icon-battery-empty {
  &::before {
    content: $icon-battery-empty;
  }
}

.icon-battery-empty-1 {
  &::before {
    content: $icon-battery-empty-1;
  }
}

.icon-battery-full {
  &::before {
    content: $icon-battery-full;
  }
}

.icon-bill {
  &::before {
    content: $icon-bill;
  }
}

.icon-book {
  &::before {
    content: $icon-book;
  }
}

.icon-book-1 {
  &::before {
    content: $icon-book-1;
  }
}

.icon-bookmark {
  &::before {
    content: $icon-bookmark;
  }
}

.icon-bookmark-1 {
  &::before {
    content: $icon-bookmark-1;
  }
}

.icon-bookmark-2 {
  &::before {
    content: $icon-bookmark-2;
  }
}

.icon-book-saved {
  &::before {
    content: $icon-book-saved;
  }
}

.icon-book-square {
  &::before {
    content: $icon-book-square;
  }
}

.icon-box-2 {
  &::before {
    content: $icon-box-2;
  }
}

.icon-briefcase {
  &::before {
    content: $icon-briefcase;
  }
}

.icon-briefcase-cross {
  &::before {
    content: $icon-briefcase-cross;
  }
}

.icon-briefcase-tick {
  &::before {
    content: $icon-briefcase-tick;
  }
}

.icon-briefcase-timer {
  &::before {
    content: $icon-briefcase-timer;
  }
}

.icon-broom {
  &::before {
    content: $icon-broom;
  }
}

.icon-bubble {
  &::before {
    content: $icon-bubble;
  }
}

.icon-buy {
  &::before {
    content: $icon-buy;
  }
}

.icon-cake {
  &::before {
    content: $icon-cake;
  }
}

.icon-calculator {
  &::before {
    content: $icon-calculator;
  }
}

.icon-calendar {
  &::before {
    content: $icon-calendar;
  }
}

.icon-calendar-1 {
  &::before {
    content: $icon-calendar-1;
  }
}

.icon-calendar-2 {
  &::before {
    content: $icon-calendar-2;
  }
}

.icon-calendar-3 {
  &::before {
    content: $icon-calendar-3;
  }
}

.icon-calendar-add {
  &::before {
    content: $icon-calendar-add;
  }
}

.icon-calendar-circle {
  &::before {
    content: $icon-calendar-circle;
  }
}

.icon-calendar-edit {
  &::before {
    content: $icon-calendar-edit;
  }
}

.icon-calendar-remove {
  &::before {
    content: $icon-calendar-remove;
  }
}

.icon-calendar-search {
  &::before {
    content: $icon-calendar-search;
  }
}

.icon-calendar-tick {
  &::before {
    content: $icon-calendar-tick;
  }
}

.icon-call {
  &::before {
    content: $icon-call;
  }
}

.icon-call-1 {
  &::before {
    content: $icon-call-1;
  }
}

.icon-call-add {
  &::before {
    content: $icon-call-add;
  }
}

.icon-call-calling {
  &::before {
    content: $icon-call-calling;
  }
}

.icon-call-incoming {
  &::before {
    content: $icon-call-incoming;
  }
}

.icon-calling {
  &::before {
    content: $icon-calling;
  }
}

.icon-call-minus {
  &::before {
    content: $icon-call-minus;
  }
}

.icon-call-missed {
  &::before {
    content: $icon-call-missed;
  }
}

.icon-call-outgoing {
  &::before {
    content: $icon-call-outgoing;
  }
}

.icon-call-received {
  &::before {
    content: $icon-call-received;
  }
}

.icon-call-remove {
  &::before {
    content: $icon-call-remove;
  }
}

.icon-call-silent {
  &::before {
    content: $icon-call-silent;
  }
}

.icon-call-slash {
  &::before {
    content: $icon-call-slash;
  }
}

.icon-camera {
  &::before {
    content: $icon-camera;
  }
}

.icon-candle {
  &::before {
    content: $icon-candle;
  }
}

.icon-candle-2 {
  &::before {
    content: $icon-candle-2;
  }
}

.icon-category {
  &::before {
    content: $icon-category;
  }
}

.icon-category-1 {
  &::before {
    content: $icon-category-1;
  }
}

.icon-category-2 {
  &::before {
    content: $icon-category-2;
  }
}

.icon-cd {
  &::before {
    content: $icon-cd;
  }
}

.icon-chart {
  &::before {
    content: $icon-chart;
  }
}

.icon-chart-1 {
  &::before {
    content: $icon-chart-1;
  }
}

.icon-chart-2 {
  &::before {
    content: $icon-chart-2;
  }
}

.icon-chart-3 {
  &::before {
    content: $icon-chart-3;
  }
}

.icon-chart-4 {
  &::before {
    content: $icon-chart-4;
  }
}

.icon-chart-21 {
  &::before {
    content: $icon-chart-21;
  }
}

.icon-chart-fail {
  &::before {
    content: $icon-chart-fail;
  }
}

.icon-chart-success {
  &::before {
    content: $icon-chart-success;
  }
}

.icon-chat {
  &::before {
    content: $icon-chat;
  }
}

.icon-check {
  &::before {
    content: $icon-check;
  }
}

.icon-chrome {
  &::before {
    content: $icon-chrome;
  }
}

.icon-clipboard {
  &::before {
    content: $icon-clipboard;
  }
}

.icon-clipboard-close {
  &::before {
    content: $icon-clipboard-close;
  }
}

.icon-clipboard-export {
  &::before {
    content: $icon-clipboard-export;
  }
}

.icon-clipboard-import {
  &::before {
    content: $icon-clipboard-import;
  }
}

.icon-clipboard-text {
  &::before {
    content: $icon-clipboard-text;
  }
}

.icon-clipboard-tick {
  &::before {
    content: $icon-clipboard-tick;
  }
}

.icon-clock {
  &::before {
    content: $icon-clock;
  }
}

.icon-close-circle {
  &::before {
    content: $icon-close-circle;
  }
}

.icon-close-square {
  &::before {
    content: $icon-close-square;
  }
}

.icon-clubhouse---negative {
  &::before {
    content: $icon-clubhouse---negative;
  }
}

.icon-coffee {
  &::before {
    content: $icon-coffee;
  }
}

.icon-computing {
  &::before {
    content: $icon-computing;
  }
}

.icon-convert {
  &::before {
    content: $icon-convert;
  }
}

.icon-copyright {
  &::before {
    content: $icon-copyright;
  }
}

.icon-creative-commons {
  &::before {
    content: $icon-creative-commons;
  }
}

.icon-crown {
  &::before {
    content: $icon-crown;
  }
}

.icon-crown-1 {
  &::before {
    content: $icon-crown-1;
  }
}

.icon-cup {
  &::before {
    content: $icon-cup;
  }
}

.icon-danger {
  &::before {
    content: $icon-danger;
  }
}

.icon-danger-1 {
  &::before {
    content: $icon-danger-1;
  }
}

.icon-delete {
  &::before {
    content: $icon-delete;
  }
}

.icon-device-message {
  &::before {
    content: $icon-device-message;
  }
}

.icon-diagram {
  &::before {
    content: $icon-diagram;
  }
}

.icon-diamonds {
  &::before {
    content: $icon-diamonds;
  }
}

.icon-direct {
  &::before {
    content: $icon-direct;
  }
}

.icon-directbox-default {
  &::before {
    content: $icon-directbox-default;
  }
}

.icon-directbox-notif {
  &::before {
    content: $icon-directbox-notif;
  }
}

.icon-directbox-receive {
  &::before {
    content: $icon-directbox-receive;
  }
}

.icon-directbox-send {
  &::before {
    content: $icon-directbox-send;
  }
}

.icon-direct-down {
  &::before {
    content: $icon-direct-down;
  }
}

.icon-direct-inbox {
  &::before {
    content: $icon-direct-inbox;
  }
}

.icon-direct-left {
  &::before {
    content: $icon-direct-left;
  }
}

.icon-direct-normal {
  &::before {
    content: $icon-direct-normal;
  }
}

.icon-direct-notification {
  &::before {
    content: $icon-direct-notification;
  }
}

.icon-direct-right {
  &::before {
    content: $icon-direct-right;
  }
}

.icon-direct-send {
  &::before {
    content: $icon-direct-send;
  }
}

.icon-direct-up {
  &::before {
    content: $icon-direct-up;
  }
}

.icon-discord-negative {
  &::before {
    content: $icon-discord-negative;
  }
}

.icon-discount {
  &::before {
    content: $icon-discount;
  }
}

.icon-discover {
  &::before {
    content: $icon-discover;
  }
}

.icon-discover-1 {
  &::before {
    content: $icon-discover-1;
  }
}

.icon-discovery {
  &::before {
    content: $icon-discovery;
  }
}

.icon-dislike {
  &::before {
    content: $icon-dislike;
  }
}

.icon-document {
  &::before {
    content: $icon-document;
  }
}

.icon-document-1 {
  &::before {
    content: $icon-document-1;
  }
}

.icon-document-cloud {
  &::before {
    content: $icon-document-cloud;
  }
}

.icon-document-copy {
  &::before {
    content: $icon-document-copy;
  }
}

.icon-document-download {
  &::before {
    content: $icon-document-download;
  }
}

.icon-document-favorite {
  &::before {
    content: $icon-document-favorite;
  }
}

.icon-document-filter {
  &::before {
    content: $icon-document-filter;
  }
}

.icon-document-forward {
  &::before {
    content: $icon-document-forward;
  }
}

.icon-document-like {
  &::before {
    content: $icon-document-like;
  }
}

.icon-document-normal {
  &::before {
    content: $icon-document-normal;
  }
}

.icon-document-previous {
  &::before {
    content: $icon-document-previous;
  }
}

.icon-document-sketch {
  &::before {
    content: $icon-document-sketch;
  }
}

.icon-document-text {
  &::before {
    content: $icon-document-text;
  }
}

.icon-document-text-1 {
  &::before {
    content: $icon-document-text-1;
  }
}

.icon-document-upload {
  &::before {
    content: $icon-document-upload;
  }
}

.icon-download {
  &::before {
    content: $icon-download;
  }
}

.icon-dribbble-negative {
  &::before {
    content: $icon-dribbble-negative;
  }
}

.icon-edit {
  &::before {
    content: $icon-edit;
  }
}

.icon-edit-1 {
  &::before {
    content: $icon-edit-1;
  }
}

.icon-edit-2 {
  &::before {
    content: $icon-edit-2;
  }
}

.icon-edit-square {
  &::before {
    content: $icon-edit-square;
  }
}

.icon-emoji-happy {
  &::before {
    content: $icon-emoji-happy;
  }
}

.icon-emoji-normal {
  &::before {
    content: $icon-emoji-normal;
  }
}

.icon-emoji-sad {
  &::before {
    content: $icon-emoji-sad;
  }
}

.icon-export {
  &::before {
    content: $icon-export;
  }
}

.icon-export-1 {
  &::before {
    content: $icon-export-1;
  }
}

.icon-export-2 {
  &::before {
    content: $icon-export-2;
  }
}

.icon-export-3 {
  &::before {
    content: $icon-export-3;
  }
}

.icon-eye {
  &::before {
    content: $icon-eye;
  }
}

.icon-eye-slash {
  &::before {
    content: $icon-eye-slash;
  }
}

.icon-facebook-negative {
  &::before {
    content: $icon-facebook-negative;
  }
}

.icon-favorite-chart {
  &::before {
    content: $icon-favorite-chart;
  }
}

.icon-figma-negative {
  &::before {
    content: $icon-figma-negative;
  }
}

.icon-filter {
  &::before {
    content: $icon-filter;
  }
}

.icon-filter-1 {
  &::before {
    content: $icon-filter-1;
  }
}

.icon-filter-2 {
  &::before {
    content: $icon-filter-2;
  }
}

.icon-filter-add {
  &::before {
    content: $icon-filter-add;
  }
}

.icon-filter-edit {
  &::before {
    content: $icon-filter-edit;
  }
}

.icon-filter-remove {
  &::before {
    content: $icon-filter-remove;
  }
}

.icon-filter-search {
  &::before {
    content: $icon-filter-search;
  }
}

.icon-filter-square {
  &::before {
    content: $icon-filter-square;
  }
}

.icon-filter-tick {
  &::before {
    content: $icon-filter-tick;
  }
}

.icon-finger-circle {
  &::before {
    content: $icon-finger-circle;
  }
}

.icon-finger-scan {
  &::before {
    content: $icon-finger-scan;
  }
}

.icon-flag {
  &::before {
    content: $icon-flag;
  }
}

.icon-flag-2 {
  &::before {
    content: $icon-flag-2;
  }
}

.icon-flash {
  &::before {
    content: $icon-flash;
  }
}

.icon-flash-circle {
  &::before {
    content: $icon-flash-circle;
  }
}

.icon-flash-slash {
  &::before {
    content: $icon-flash-slash;
  }
}

.icon-folder {
  &::before {
    content: $icon-folder;
  }
}

.icon-folder-1 {
  &::before {
    content: $icon-folder-1;
  }
}

.icon-folder-2 {
  &::before {
    content: $icon-folder-2;
  }
}

.icon-folder-add {
  &::before {
    content: $icon-folder-add;
  }
}

.icon-folder-cloud {
  &::before {
    content: $icon-folder-cloud;
  }
}

.icon-folder-cross {
  &::before {
    content: $icon-folder-cross;
  }
}

.icon-folder-favorite {
  &::before {
    content: $icon-folder-favorite;
  }
}

.icon-folder-minus {
  &::before {
    content: $icon-folder-minus;
  }
}

.icon-folder-open {
  &::before {
    content: $icon-folder-open;
  }
}

.icon-forbidden {
  &::before {
    content: $icon-forbidden;
  }
}

.icon-forbidden-2 {
  &::before {
    content: $icon-forbidden-2;
  }
}

.icon-forward-square {
  &::before {
    content: $icon-forward-square;
  }
}

.icon-frame {
  &::before {
    content: $icon-frame;
  }
}

.icon-frame-1 {
  &::before {
    content: $icon-frame-1;
  }
}

.icon-frame-2 {
  &::before {
    content: $icon-frame-2;
  }
}

.icon-game {
  &::before {
    content: $icon-game;
  }
}

.icon-ghost {
  &::before {
    content: $icon-ghost;
  }
}

.icon-gift {
  &::before {
    content: $icon-gift;
  }
}

.icon-github-negative {
  &::before {
    content: $icon-github-negative;
  }
}

.icon-glass {
  &::before {
    content: $icon-glass;
  }
}

.icon-global {
  &::before {
    content: $icon-global;
  }
}

.icon-global-edit {
  &::before {
    content: $icon-global-edit;
  }
}

.icon-global-refresh {
  &::before {
    content: $icon-global-refresh;
  }
}

.icon-global-search {
  &::before {
    content: $icon-global-search;
  }
}

.icon-google---negative {
  &::before {
    content: $icon-google---negative;
  }
}

.icon-gps {
  &::before {
    content: $icon-gps;
  }
}

.icon-gps-slash {
  &::before {
    content: $icon-gps-slash;
  }
}

.icon-grammarly {
  &::before {
    content: $icon-grammarly;
  }
}

.icon-graph {
  &::before {
    content: $icon-graph;
  }
}

.icon-graph-1 {
  &::before {
    content: $icon-graph-1;
  }
}

.icon-happyemoji {
  &::before {
    content: $icon-happyemoji;
  }
}

.icon-hashtag {
  &::before {
    content: $icon-hashtag;
  }
}

.icon-health {
  &::before {
    content: $icon-health;
  }
}

.icon-heart {
  &::before {
    content: $icon-heart;
  }
}

.icon-heart-1 {
  &::before {
    content: $icon-heart-1;
  }
}

.icon-heart-add {
  &::before {
    content: $icon-heart-add;
  }
}

.icon-heart-circle {
  &::before {
    content: $icon-heart-circle;
  }
}

.icon-heart-edit {
  &::before {
    content: $icon-heart-edit;
  }
}

.icon-heart-remove {
  &::before {
    content: $icon-heart-remove;
  }
}

.icon-heart-search {
  &::before {
    content: $icon-heart-search;
  }
}

.icon-heart-slash {
  &::before {
    content: $icon-heart-slash;
  }
}

.icon-heart-tick {
  &::before {
    content: $icon-heart-tick;
  }
}

.icon-hide {
  &::before {
    content: $icon-hide;
  }
}

.icon-home {
  &::before {
    content: $icon-home;
  }
}

.icon-home-1 {
  &::before {
    content: $icon-home-1;
  }
}

.icon-home-2 {
  &::before {
    content: $icon-home-2;
  }
}

.icon-home-3 {
  &::before {
    content: $icon-home-3;
  }
}

.icon-home-hashtag {
  &::before {
    content: $icon-home-hashtag;
  }
}

.icon-home-trend-down {
  &::before {
    content: $icon-home-trend-down;
  }
}

.icon-home-trend-up {
  &::before {
    content: $icon-home-trend-up;
  }
}

.icon-home-wifi {
  &::before {
    content: $icon-home-wifi;
  }
}

.icon-image {
  &::before {
    content: $icon-image;
  }
}

.icon-image-2 {
  &::before {
    content: $icon-image-2;
  }
}

.icon-import {
  &::before {
    content: $icon-import;
  }
}

.icon-import-1 {
  &::before {
    content: $icon-import-1;
  }
}

.icon-import-2 {
  &::before {
    content: $icon-import-2;
  }
}

.icon-info-circle {
  &::before {
    content: $icon-info-circle;
  }
}

.icon-info-circle-1 {
  &::before {
    content: $icon-info-circle-1;
  }
}

.icon-information {
  &::before {
    content: $icon-information;
  }
}

.icon-info-square {
  &::before {
    content: $icon-info-square;
  }
}

.icon-instagram {
  &::before {
    content: $icon-instagram;
  }
}

.icon-instagram-negative {
  &::before {
    content: $icon-instagram-negative;
  }
}

.icon-judge {
  &::before {
    content: $icon-judge;
  }
}

.icon-key {
  &::before {
    content: $icon-key;
  }
}

.icon-key-square {
  &::before {
    content: $icon-key-square;
  }
}

.icon-lamp {
  &::before {
    content: $icon-lamp;
  }
}

.icon-lamp-charge {
  &::before {
    content: $icon-lamp-charge;
  }
}

.icon-lamp-on {
  &::before {
    content: $icon-lamp-on;
  }
}

.icon-lamp-slash {
  &::before {
    content: $icon-lamp-slash;
  }
}

.icon-level {
  &::before {
    content: $icon-level;
  }
}

.icon-like {
  &::before {
    content: $icon-like;
  }
}

.icon-like-1 {
  &::before {
    content: $icon-like-1;
  }
}

.icon-like-dislike {
  &::before {
    content: $icon-like-dislike;
  }
}

.icon-like-shapes {
  &::before {
    content: $icon-like-shapes;
  }
}

.icon-like-tag {
  &::before {
    content: $icon-like-tag;
  }
}

.icon-linkedin-negative {
  &::before {
    content: $icon-linkedin-negative;
  }
}

.icon-location {
  &::before {
    content: $icon-location;
  }
}

.icon-location-1 {
  &::before {
    content: $icon-location-1;
  }
}

.icon-location-add {
  &::before {
    content: $icon-location-add;
  }
}

.icon-location-cross {
  &::before {
    content: $icon-location-cross;
  }
}

.icon-location-minus {
  &::before {
    content: $icon-location-minus;
  }
}

.icon-location-slash {
  &::before {
    content: $icon-location-slash;
  }
}

.icon-location-tick {
  &::before {
    content: $icon-location-tick;
  }
}

.icon-lock {
  &::before {
    content: $icon-lock;
  }
}

.icon-lock-1 {
  &::before {
    content: $icon-lock-1;
  }
}

.icon-lock-2 {
  &::before {
    content: $icon-lock-2;
  }
}

.icon-lock-circle {
  &::before {
    content: $icon-lock-circle;
  }
}

.icon-lock-slash {
  &::before {
    content: $icon-lock-slash;
  }
}

.icon-login {
  &::before {
    content: $icon-login;
  }
}

.icon-login-1 {
  &::before {
    content: $icon-login-1;
  }
}

.icon-login-2 {
  &::before {
    content: $icon-login-2;
  }
}

.icon-logout {
  &::before {
    content: $icon-logout;
  }
}

.icon-logout-1 {
  &::before {
    content: $icon-logout-1;
  }
}

.icon-logout-2 {
  &::before {
    content: $icon-logout-2;
  }
}

.icon-lovely {
  &::before {
    content: $icon-lovely;
  }
}

.icon-magic-star {
  &::before {
    content: $icon-magic-star;
  }
}

.icon-map {
  &::before {
    content: $icon-map;
  }
}

.icon-map-1 {
  &::before {
    content: $icon-map-1;
  }
}

.icon-medal {
  &::before {
    content: $icon-medal;
  }
}

.icon-medal-star {
  &::before {
    content: $icon-medal-star;
  }
}

.icon-medium-negative {
  &::before {
    content: $icon-medium-negative;
  }
}

.icon-menu {
  &::before {
    content: $icon-menu;
  }
}

.icon-menu-1 {
  &::before {
    content: $icon-menu-1;
  }
}

.icon-menu-board {
  &::before {
    content: $icon-menu-board;
  }
}

.icon-menu-side {
  &::before {
    content: $icon-menu-side;
    color: #677179;
  }
}

.icon-message {
  &::before {
    content: $icon-message;
  }
}

.icon-message-1 {
  &::before {
    content: $icon-message-1;
  }
}

.icon-message-2 {
  &::before {
    content: $icon-message-2;
  }
}

.icon-message-add {
  &::before {
    content: $icon-message-add;
  }
}

.icon-message-add-1 {
  &::before {
    content: $icon-message-add-1;
  }
}

.icon-message-circle {
  &::before {
    content: $icon-message-circle;
  }
}

.icon-message-edit {
  &::before {
    content: $icon-message-edit;
  }
}

.icon-message-favorite {
  &::before {
    content: $icon-message-favorite;
  }
}

.icon-message-minus {
  &::before {
    content: $icon-message-minus;
  }
}

.icon-message-notif {
  &::before {
    content: $icon-message-notif;
  }
}

.icon-message-question {
  &::before {
    content: $icon-message-question;
  }
}

.icon-message-remove {
  &::before {
    content: $icon-message-remove;
  }
}

.icon-messages {
  &::before {
    content: $icon-messages;
  }
}

.icon-messages-1 {
  &::before {
    content: $icon-messages-1;
  }
}

.icon-messages-2 {
  &::before {
    content: $icon-messages-2;
  }
}

.icon-messages-3 {
  &::before {
    content: $icon-messages-3;
  }
}

.icon-message-search {
  &::before {
    content: $icon-message-search;
  }
}

.icon-message-square {
  &::before {
    content: $icon-message-square;
  }
}

.icon-message-text {
  &::before {
    content: $icon-message-text;
  }
}

.icon-message-text-1 {
  &::before {
    content: $icon-message-text-1;
  }
}

.icon-message-tick {
  &::before {
    content: $icon-message-tick;
  }
}

.icon-message-time {
  &::before {
    content: $icon-message-time;
  }
}

.icon-milk {
  &::before {
    content: $icon-milk;
  }
}

.icon-minus {
  &::before {
    content: $icon-minus;
  }
}

.icon-minus-circle {
  &::before {
    content: $icon-minus-circle;
  }
}

.icon-minus-square {
  &::before {
    content: $icon-minus-square;
  }
}

.icon-mirror {
  &::before {
    content: $icon-mirror;
  }
}

.icon-more {
  &::before {
    content: $icon-more;
  }
}

.icon-more-2 {
  &::before {
    content: $icon-more-2;
  }
}

.icon-more-circle {
  &::before {
    content: $icon-more-circle;
  }
}

.icon-more-circle-1 {
  &::before {
    content: $icon-more-circle-1;
  }
}

.icon-more-square {
  &::before {
    content: $icon-more-square;
  }
}

.icon-more-square-1 {
  &::before {
    content: $icon-more-square-1;
  }
}

.icon-mouse {
  &::before {
    content: $icon-mouse;
  }
}

.icon-mouse-circle {
  &::before {
    content: $icon-mouse-circle;
  }
}

.icon-mouse-square {
  &::before {
    content: $icon-mouse-square;
  }
}

.icon-note {
  &::before {
    content: $icon-note;
  }
}

.icon-note-1 {
  &::before {
    content: $icon-note-1;
  }
}

.icon-note-2 {
  &::before {
    content: $icon-note-2;
  }
}

.icon-note-21 {
  &::before {
    content: $icon-note-21;
  }
}

.icon-note-add {
  &::before {
    content: $icon-note-add;
  }
}

.icon-note-favorite {
  &::before {
    content: $icon-note-favorite;
  }
}

.icon-note-remove {
  &::before {
    content: $icon-note-remove;
  }
}

.icon-note-text {
  &::before {
    content: $icon-note-text;
  }
}

.icon-notification {
  &::before {
    content: $icon-notification;
  }
}

.icon-notification-1 {
  &::before {
    content: $icon-notification-1;
  }
}

.icon-notification-2 {
  &::before {
    content: $icon-notification-2;
  }
}

.icon-notification-bing {
  &::before {
    content: $icon-notification-bing;
  }
}

.icon-notification-circle {
  &::before {
    content: $icon-notification-circle;
  }
}

.icon-notification-favorite {
  &::before {
    content: $icon-notification-favorite;
  }
}

.icon-notification-status {
  &::before {
    content: $icon-notification-status;
  }
}

.icon-paper {
  &::before {
    content: $icon-paper;
  }
}

.icon-paper-download {
  &::before {
    content: $icon-paper-download;
  }
}

.icon-paper-fail {
  &::before {
    content: $icon-paper-fail;
  }
}

.icon-paper-negative {
  &::before {
    content: $icon-paper-negative;
  }
}

.icon-paper-plus {
  &::before {
    content: $icon-paper-plus;
  }
}

.icon-paper-upload {
  &::before {
    content: $icon-paper-upload;
  }
}

.icon-password {
  &::before {
    content: $icon-password;
  }
}

.icon-password-check {
  &::before {
    content: $icon-password-check;
  }
}

.icon-people {
  &::before {
    content: $icon-people;
  }
}

.icon-personalcard {
  &::before {
    content: $icon-personalcard;
  }
}

.icon-pet {
  &::before {
    content: $icon-pet;
  }
}

.icon-picture-frame {
  &::before {
    content: $icon-picture-frame;
  }
}

.icon-pinterest-negative {
  &::before {
    content: $icon-pinterest-negative;
  }
}

.icon-play {
  &::before {
    content: $icon-play;
  }
}

.icon-plus {
  &::before {
    content: $icon-plus;
  }
}

.icon-presentation-chart {
  &::before {
    content: $icon-presentation-chart;
  }
}

.icon-profile {
  &::before {
    content: $icon-profile;
  }
}

.icon-profile-2-user {
  &::before {
    content: $icon-profile-2-user;
  }
}

.icon-profile-add {
  &::before {
    content: $icon-profile-add;
  }
}

.icon-profile-circle {
  &::before {
    content: $icon-profile-circle;
  }
}

.icon-profile-delete {
  &::before {
    content: $icon-profile-delete;
  }
}

.icon-profile-remove {
  &::before {
    content: $icon-profile-remove;
  }
}

.icon-profile-tick {
  &::before {
    content: $icon-profile-tick;
  }
}

.icon-radar {
  &::before {
    content: $icon-radar;
  }
}

.icon-radar-1 {
  &::before {
    content: $icon-radar-1;
  }
}

.icon-radar-2 {
  &::before {
    content: $icon-radar-2;
  }
}

.icon-ranking {
  &::before {
    content: $icon-ranking;
  }
}

.icon-ranking-1 {
  &::before {
    content: $icon-ranking-1;
  }
}

.icon-receipt-square {
  &::before {
    content: $icon-receipt-square;
  }
}

.icon-received {
  &::before {
    content: $icon-received;
  }
}

.icon-receive-square {
  &::before {
    content: $icon-receive-square;
  }
}

.icon-receive-square-2 {
  &::before {
    content: $icon-receive-square-2;
  }
}

.icon-reddit-negative {
  &::before {
    content: $icon-reddit-negative;
  }
}

.icon-redo {
  &::before {
    content: $icon-redo;
  }
}

.icon-refresh {
  &::before {
    content: $icon-refresh;
  }
}

.icon-refresh-2 {
  &::before {
    content: $icon-refresh-2;
  }
}

.icon-refresh-circle {
  &::before {
    content: $icon-refresh-circle;
  }
}

.icon-refresh-left-square {
  &::before {
    content: $icon-refresh-left-square;
  }
}

.icon-refresh-right-square {
  &::before {
    content: $icon-refresh-right-square;
  }
}

.icon-refresh-square-2 {
  &::before {
    content: $icon-refresh-square-2;
  }
}

.icon-repeat {
  &::before {
    content: $icon-repeat;
  }
}

.icon-repeat-circle {
  &::before {
    content: $icon-repeat-circle;
  }
}

.icon-reserve {
  &::before {
    content: $icon-reserve;
  }
}

.icon-rotate-left {
  &::before {
    content: $icon-rotate-left;
  }
}

.icon-rotate-right {
  &::before {
    content: $icon-rotate-right;
  }
}

.icon-route-square {
  &::before {
    content: $icon-route-square;
  }
}

.icon-routing {
  &::before {
    content: $icon-routing;
  }
}

.icon-routing-2 {
  &::before {
    content: $icon-routing-2;
  }
}

.icon-safe-home {
  &::before {
    content: $icon-safe-home;
  }
}

.icon-save-2 {
  &::before {
    content: $icon-save-2;
  }
}

.icon-save-add {
  &::before {
    content: $icon-save-add;
  }
}

.icon-save-minus {
  &::before {
    content: $icon-save-minus;
  }
}

.icon-save-remove {
  &::before {
    content: $icon-save-remove;
  }
}

.icon-scan {
  &::before {
    content: $icon-scan;
  }
}

.icon-scan-1 {
  &::before {
    content: $icon-scan-1;
  }
}

.icon-scan-barcode {
  &::before {
    content: $icon-scan-barcode;
  }
}

.icon-scanner {
  &::before {
    content: $icon-scanner;
  }
}

.icon-scanning {
  &::before {
    content: $icon-scanning;
  }
}

.icon-search {
  &::before {
    content: $icon-search;
  }
}

.icon-search-favorite {
  &::before {
    content: $icon-search-favorite;
  }
}

.icon-search-favorite-1 {
  &::before {
    content: $icon-search-favorite-1;
  }
}

.icon-search-normal {
  &::before {
    content: $icon-search-normal;
  }
}

.icon-search-normal-1 {
  &::before {
    content: $icon-search-normal-1;
  }
}

.icon-search-status {
  &::before {
    content: $icon-search-status;
  }
}

.icon-search-status-1 {
  &::before {
    content: $icon-search-status-1;
  }
}

.icon-search-zoom-in {
  &::before {
    content: $icon-search-zoom-in;
  }
}

.icon-search-zoom-in-1 {
  &::before {
    content: $icon-search-zoom-in-1;
  }
}

.icon-search-zoom-out {
  &::before {
    content: $icon-search-zoom-out;
  }
}

.icon-search-zoom-out-1 {
  &::before {
    content: $icon-search-zoom-out-1;
  }
}

.icon-security {
  &::before {
    content: $icon-security;
  }
}

.icon-security-safe {
  &::before {
    content: $icon-security-safe;
  }
}

.icon-security-time {
  &::before {
    content: $icon-security-time;
  }
}

.icon-security-user {
  &::before {
    content: $icon-security-user;
  }
}

.icon-send {
  &::before {
    content: $icon-send;
  }
}

.icon-send-1 {
  &::before {
    content: $icon-send-1;
  }
}

.icon-send-2 {
  &::before {
    content: $icon-send-2;
  }
}

.icon-send-3 {
  &::before {
    content: $icon-send-3;
  }
}

.icon-send-square {
  &::before {
    content: $icon-send-square;
  }
}

.icon-send-square-2 {
  &::before {
    content: $icon-send-square-2;
  }
}

.icon-setting {
  &::before {
    content: $icon-setting;
  }
}

.icon-setting-2 {
  &::before {
    content: $icon-setting-2;
  }
}

.icon-setting-3 {
  &::before {
    content: $icon-setting-3;
  }
}

.icon-setting-4 {
  &::before {
    content: $icon-setting-4;
  }
}

.icon-setting-5 {
  &::before {
    content: $icon-setting-5;
  }
}

.icon-setting-6 {
  &::before {
    content: $icon-setting-6;
  }
}

.icon-settings {
  &::before {
    content: $icon-settings;
  }
}

.icon-share {
  &::before {
    content: $icon-share;
  }
}

.icon-shield {
  &::before {
    content: $icon-shield;
  }
}

.icon-shield-cross {
  &::before {
    content: $icon-shield-cross;
  }
}

.icon-shield-done {
  &::before {
    content: $icon-shield-done;
  }
}

.icon-shield-fail {
  &::before {
    content: $icon-shield-fail;
  }
}

.icon-shield-search {
  &::before {
    content: $icon-shield-search;
  }
}

.icon-shield-slash {
  &::before {
    content: $icon-shield-slash;
  }
}

.icon-shield-tick {
  &::before {
    content: $icon-shield-tick;
  }
}

.icon-show {
  &::before {
    content: $icon-show;
  }
}

.icon-signal-negative {
  &::before {
    content: $icon-signal-negative;
  }
}

.icon-signpost {
  &::before {
    content: $icon-signpost;
  }
}

.icon-slash {
  &::before {
    content: $icon-slash;
  }
}

.icon-slider {
  &::before {
    content: $icon-slider;
  }
}

.icon-smart-home {
  &::before {
    content: $icon-smart-home;
  }
}

.icon-smileys {
  &::before {
    content: $icon-smileys;
  }
}

.icon-sms {
  &::before {
    content: $icon-sms;
  }
}

.icon-sms-edit {
  &::before {
    content: $icon-sms-edit;
  }
}

.icon-sms-notification {
  &::before {
    content: $icon-sms-notification;
  }
}

.icon-sms-search {
  &::before {
    content: $icon-sms-search;
  }
}

.icon-sms-star {
  &::before {
    content: $icon-sms-star;
  }
}

.icon-sms-tracking {
  &::before {
    content: $icon-sms-tracking;
  }
}

.icon-snapchat-negative {
  &::before {
    content: $icon-snapchat-negative;
  }
}

.icon-sort {
  &::before {
    content: $icon-sort;
  }
}

.icon-sound {
  &::before {
    content: $icon-sound;
  }
}

.icon-speedometer {
  &::before {
    content: $icon-speedometer;
  }
}

.icon-spotify-negative {
  &::before {
    content: $icon-spotify-negative;
  }
}

.icon-star {
  &::before {
    content: $icon-star;
  }
}

.icon-star-1 {
  &::before {
    content: $icon-star-1;
  }
}

.icon-star-2 {
  &::before {
    content: $icon-star-2;
  }
}

.icon-star-slash {
  &::before {
    content: $icon-star-slash;
  }
}

.icon-status {
  &::before {
    content: $icon-status;
  }
}

.icon-status-up {
  &::before {
    content: $icon-status-up;
  }
}

.icon-sticker {
  &::before {
    content: $icon-sticker;
  }
}

.icon-stickynote {
  &::before {
    content: $icon-stickynote;
  }
}

.icon-story {
  &::before {
    content: $icon-story;
  }
}

.icon-swap {
  &::before {
    content: $icon-swap;
  }
}

.icon-tag-cross {
  &::before {
    content: $icon-tag-cross;
  }
}

.icon-tag-right {
  &::before {
    content: $icon-tag-right;
  }
}

.icon-tag-user {
  &::before {
    content: $icon-tag-user;
  }
}

.icon-task {
  &::before {
    content: $icon-task;
  }
}

.icon-task-square {
  &::before {
    content: $icon-task-square;
  }
}

.icon-teacher {
  &::before {
    content: $icon-teacher;
  }
}

.icon-telegram-negative {
  &::before {
    content: $icon-telegram-negative;
  }
}

.icon-tick-circle {
  &::before {
    content: $icon-tick-circle;
  }
}

.icon-ticket {
  &::before {
    content: $icon-ticket;
  }
}

.icon-ticket-star {
  &::before {
    content: $icon-ticket-star;
  }
}

.icon-tick-square {
  &::before {
    content: $icon-tick-square;
  }
}

.icon-tiktok-negative {
  &::before {
    content: $icon-tiktok-negative;
  }
}

.icon-time-circle {
  &::before {
    content: $icon-time-circle;
  }
}

.icon-timer {
  &::before {
    content: $icon-timer;
  }
}

.icon-timer-1 {
  &::before {
    content: $icon-timer-1;
  }
}

.icon-timer-pause {
  &::before {
    content: $icon-timer-pause;
  }
}

.icon-timer-start {
  &::before {
    content: $icon-timer-start;
  }
}

.icon-time-square {
  &::before {
    content: $icon-time-square;
  }
}

.icon-toggle-off {
  &::before {
    content: $icon-toggle-off;
  }
}

.icon-toggle-off-circle {
  &::before {
    content: $icon-toggle-off-circle;
  }
}

.icon-toggle-on {
  &::before {
    content: $icon-toggle-on;
  }
}

.icon-toggle-on-circle {
  &::before {
    content: $icon-toggle-on-circle;
  }
}

.icon-trash {
  &::before {
    content: $icon-trash;
  }
}

.icon-trash-square {
  &::before {
    content: $icon-trash-square;
  }
}

.icon-tree {
  &::before {
    content: $icon-tree;
  }
}

.icon-trend-down {
  &::before {
    content: $icon-trend-down;
  }
}

.icon-trend-up {
  &::before {
    content: $icon-trend-up;
  }
}

.icon-triangle {
  &::before {
    content: $icon-triangle;
  }
}

.icon-tumblr-negative {
  &::before {
    content: $icon-tumblr-negative;
  }
}

.icon-twitter-negative {
  &::before {
    content: $icon-twitter-negative;
  }
}

.icon-undo {
  &::before {
    content: $icon-undo;
  }
}

.icon-unlimited {
  &::before {
    content: $icon-unlimited;
  }
}

.icon-unlock {
  &::before {
    content: $icon-unlock;
  }
}

.icon-unlock-1 {
  &::before {
    content: $icon-unlock-1;
  }
}

.icon-upload {
  &::before {
    content: $icon-upload;
  }
}

.icon-user2 {
  &::before {
    content: $icon-user2;
  }
}

.icon-user-add {
  &::before {
    content: $icon-user-add;
  }
}

.icon-user-circle-add {
  &::before {
    content: $icon-user-circle-add;
  }
}

.icon-user-edit {
  &::before {
    content: $icon-user-edit;
  }
}

.icon-user-minus {
  &::before {
    content: $icon-user-minus;
  }
}

.icon-user-octagon {
  &::before {
    content: $icon-user-octagon;
  }
}

.icon-user-remove {
  &::before {
    content: $icon-user-remove;
  }
}

.icon-user-search {
  &::before {
    content: $icon-user-search;
  }
}

.icon-user-square {
  &::before {
    content: $icon-user-square;
  }
}

.icon-user-tag {
  &::before {
    content: $icon-user-tag;
  }
}

.icon-user-tick {
  &::before {
    content: $icon-user-tick;
  }
}

.icon-verify {
  &::before {
    content: $icon-verify;
  }
}

.icon-video {
  &::before {
    content: $icon-video;
  }
}

.icon-vk-negative {
  &::before {
    content: $icon-vk-negative;
  }
}

.icon-voice {
  &::before {
    content: $icon-voice;
  }
}

.icon-voice-2 {
  &::before {
    content: $icon-voice-2;
  }
}

.icon-volume-down {
  &::before {
    content: $icon-volume-down;
  }
}

.icon-volume-off {
  &::before {
    content: $icon-volume-off;
  }
}

.icon-volume-up {
  &::before {
    content: $icon-volume-up;
  }
}

.icon-wallet {
  &::before {
    content: $icon-wallet;
  }
}

.icon-warning-2 {
  &::before {
    content: $icon-warning-2;
  }
}

.icon-weight {
  &::before {
    content: $icon-weight;
  }
}

.icon-wifi {
  &::before {
    content: $icon-wifi;
  }
}

.icon-wifi-square {
  &::before {
    content: $icon-wifi-square;
  }
}

.icon-work {
  &::before {
    content: $icon-work;
  }
}

.icon-youtube-negative {
  &::before {
    content: $icon-youtube-negative;
  }
}
