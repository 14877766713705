.header {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  padding: 0 20px;

  .headerItem {
    margin-left: 50px;
    position: relative;
    display: flex;
    align-items: center;
    height: 40px;

    @media (max-width: 768px) {
      margin-left: 25px;
    }
  }
}
