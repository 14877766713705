.creation-page {
  position: relative;
  width: 100%;
}

.transition-container {
  position: absolute;
  width: 100%;
  top: 24px; // accommodate for rct-page padding
  left: 0;
}

.creation-page__transition--fade-enter {
  opacity: 0;
}

.creation-page__transition--fade-enter-active {
  opacity: 1;
  transition: opacity 400ms linear 150ms;
}

.creation-page__transition--fade-exit {
  opacity: 1;
}

.creation-page__transition--fade-exit-active {
  opacity: 0;
  transition: opacity 150ms linear;
}

.card-item-enter-enter {
  opacity: 0;
}

.card-item-enter-active {
  opacity: 1;
  transition: opacity 300ms ease-in;
}

.card-item-exit {
  opacity: 1;
}

.card-item-exit-active {
  opacity: 0;
  transition: opacity 300ms ease-in;
}
