@import "./src/assets/scss/app-variables";

.languageSelector {
  span {
    display: inline-block;
    font-size: 14px;
    line-height: 20px;
    color: $color_additional_darken-2;

    @media (max-width: 768px) {
      &.text {
        display: none;
      }
    }
  }

  img.icon {
    @media (max-width: 768px) {
      margin: auto 0;
    }
  }

  i {
    color: $color_additional_darken-2;
    font-size: 14px;
    position: absolute;
    right: 0;
    cursor: pointer;
    pointer-events: none;
  }
}

.icon {
  margin-bottom: 2px;
  margin-right: 14px;
}

.paper {
  margin-top: 20px;
  box-shadow: 0 4px 16px rgba(103, 113, 121, 0.15);
  border-radius: 6px;
}

.select {
  background-color: $color_light !important;

  .text {
    font-size: $header-font-size-sm;
  }

  &:hover {
    .text,
    ~ [class^="icon-"] {
      color: $color_primary;
    }
  }

  &Open {
    .text,
    ~ [class^="icon-"] {
      color: $color_primary;
    }

    ~ [class^="icon-"] {
      transform: rotate(180deg);
    }
  }
}

.menuItem {
  padding: 12px 10px;
  width: 264px;
  min-height: 50px;

  &:global(.Mui-selected) {
    background-color: $color_primary_lighten !important;

    &:hover {
      background-color: $color_primary_lighten !important;
    }
  }

  &:global(:hover) {
    background-color: $color_primary_lighten !important;

    span {
      color: $color_dark !important;
    }
  }

  .text {
    margin-top: 4px;
    margin-right: 6px;
    color: $color_additional_darken-2 !important;
  }
}
