.navigation-buttons {
  border: none;
  border-radius: 50%;
  text-align: center;
  margin: 5px;
  position: absolute;
  top: 48%;
  width: 2rem;
  padding: 2px;

  svg {
    display: block;
    margin: auto;
  }
}

.left-button {
  left: 0;
}

.right-button {
  right: 0;
}

.bounty-img {
  width: auto !important;
  height: auto !important;
  max-width: 100%;
  max-height: 100%;
}
