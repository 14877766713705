/* =========== Background Utilities ========== */

.gradient-primary {
  background: $gradient-primary;

  * {
    color: $white;
  }
}

.gradient-purple {
  background: $gradient-purple;

  * {
    color: $white;
  }
}

.gradient-success {
  background: $gradient-success;

  * {
    color: $white;
  }
}

.gradient-danger {
  background: $gradient-danger;

  * {
    color: $white;
  }
}

.gradient-warning {
  background: $gradient-warning;

  * {
    color: $white;
  }
}

.bg-aqua {
  background-color: $aqua;
}

.bg-light {
  background-color: $gray-200 !important;
}

.bg-grdnt-violet {
  background: linear-gradient(180deg, #03001e 0%, #7303c0 24.82%, #ec38bc 62.59%, #fdeff9 100%);
}

.bg-grdnt-youtube {
  background: linear-gradient(0deg, #f5af19 0%, #f12711 100%);
}

.bg-grdnt-primary-danger {
  background: linear-gradient(0deg, #ec2f4b 0%, #009fff 100%);
}

.bg-grdnt-purple-danger {
  background: linear-gradient(0deg, #240b36 0%, #c31432 100%);
}

.bg-grdnt-purple-dark {
  background: linear-gradient(to top, #24243e, #302b63, #0f0c29);
}
