/* ======= All App Files Include Here ====== */

// Default Bootstrap File
@import "./bootstrap";
@import "./common";

// Fonts
@import "fonts/icon-font";
@import "fonts/custom-font";

// Slick Slider
// @import "~slick-carousel/slick/slick.css";
// @import "~slick-carousel/slick/slick-theme.css";

/* ==== Core Mixins ==== */
@import "./custom/mixins/mixin";

/* ==== Core Utilities ===== */
@import "./custom/utilities/utilities";

// Custom Variables Files
@import "./custom";

// Custom Layout Files
@import "./custom/layouts/layout";

// Custom themes Files
@import "./custom/themes/themes";
@import "./status-labels";
@import "./carousel";
@import "./surveyOptions";
@import "./add-post";
@import "./orders";
@import "./scrollbar";
@import "./react-transitions";
