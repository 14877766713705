@import "./custom-font-variables";

/* rubik-regular - latin-ext_latin_cyrillic-ext_cyrillic */
@font-face {
  font-family: $custom-font-name;
  font-style: normal;
  font-weight: normal;
  src: url("#{$custom-font-path}/#{$custom-font-400}.eot"); /* IE9 Compat Modes */
  src:
    local(""),
    url("#{$custom-font-path}/#{$custom-font-400}.eot?#iefix") format("embedded-opentype"),
    url("#{$custom-font-path}/#{$custom-font-400}.woff2") format("woff2"),
    url("#{$custom-font-path}/#{$custom-font-400}.woff") format("woff"),
    url("#{$custom-font-path}/#{$custom-font-400}.ttf") format("truetype"),
    url("#{$custom-font-path}/#{$custom-font-400}.svg#Rubik") format("svg"); /* Legacy iOS */
}

/* rubik-500 - latin-ext_latin_cyrillic-ext_cyrillic */
@font-face {
  font-family: $custom-font-name;
  font-style: normal;
  font-weight: bold;
  src: url("#{$custom-font-path}/#{$custom-font-500}.eot"); /* IE9 Compat Modes */
  src:
    local(""),
    url("#{$custom-font-path}/#{$custom-font-500}.eot?#iefix") format("embedded-opentype"),
    url("#{$custom-font-path}/#{$custom-font-500}.woff2") format("woff2"),
    url("#{$custom-font-path}/#{$custom-font-500}.woff") format("woff"),
    url("#{$custom-font-path}/#{$custom-font-500}.ttf") format("truetype"),
    url("#{$custom-font-path}/#{$custom-font-500}.svg#Rubik") format("svg"); /* Legacy iOS */
}
