/* ============ Editor Style ============= */
// Wysiwyg Editor
.rct-editor-wrapper {
  .rdw-editor-toolbar {
    border: 1px solid $input-border-color !important;
    background-color: $blue;
  }

  .rct-wysiwyg-editor {
    height: 300px !important;
    border: 1px solid $input-border-color !important;
    padding: 5px 15px !important;
    background: $block-bg;
  }
}

// Quill Editor
.quill {
  overflow: hidden;
}

.ql-container {
  height: 300px;
}

.ql-container.ql-snow, .ql-toolbar.ql-snow {
  border-color: $input-border-color;
}

.editor {
  border: 1px solid #ddd;
  min-height: 100px;
  cursor: text;
  padding: 8px;
  background: #fafbfc;

  li {
    margin-left: 16px;
  }
}

.draftJsMentionPlugin__input__1Wxng {
  width: 100%;
}

.draftJsEmojiPlugin__emojiSelectButton__3sPol, .draftJsEmojiPlugin__emojiSelectButtonPressed__2Tezu {
  background: #fbfbfb;
  color: #888;
  border: 0;
  padding-top: 5px;
  vertical-align: bottom;
  height: 34px;
  width: 36px;
  border-radius: 0;
}

.draftJsEmojiPlugin__emojiSelectPopover__1J1s0 {
  width: 50%;
}

.draftJsEmojiPlugin__emojiSelectPopoverGroups__35t9m {
  height: 12em;
}

.draftJsEmojiPlugin__emojiSelectPopoverGroups__35t9m, .draftJsEmojiPlugin__emojiSelectPopoverNav__1Nzd7 {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}
