@import "./src/assets/scss/app-variables";
@import "./src/assets/scss/bootstrap-override";

.responsiveTableWrapper {
  width: 100%;
  overflow-x: auto;
}

.responsive-table {
  border-collapse: separate;
  border-spacing: 0 10px;
  word-break: normal;

  .one-line-text-wrapper {
    max-width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  tr {
    background-color: white;
  }

  @media only screen and (max-width: map-get($grid-breakpoints, "lg") - 1) {
    display: block;

    thead {
      display: none;
    }

    tbody, tr, th, td {
      display: block;
      width: 100%;
    }

    tr {
      padding: 20px;

      &:not(:last-child) {
        margin-bottom: 20px;
      }
    }

    td {
      display: flex;
      box-sizing: border-box;

      &:not(:last-child) {
        margin-bottom: 10px;
      }
    }

    .cell-inner-label {
      margin-right: 20px;
      font-weight: bold;
      word-break: normal;
    }
  }

  @media only screen and (min-width: map-get($grid-breakpoints, "lg")) {
    th, td {
      padding: 0 20px;
      height: 42px;
    }

    th {
      position: relative;
      line-height: 0;

      .header-wrapper {
        display: inline-block;
        line-height: normal;
        max-width: 100%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      &.sortable {
        padding-right: 45px;
        cursor: pointer;
        user-select: none;

        .header-wrapper {
          box-sizing: border-box;
          padding-right: 30px;
          position: relative;

          &::after {
            display: block;
            position: absolute;
            right: 0;
            top: -2px;
            content: "\f2f9";
            font-family: Material-Design-Iconic-Font, serif;
            font-size: $header-font-size-lg;
            line-height: 1;
          }
        }

        &.ASC {
          .header-wrapper::after {
            content: "\f2fc";
          }
        }
      }

      &.active {
        color: $color_primary;
      }
    }

    .cell-inner-label {
      display: none;
    }

    *:not(thead) tr:hover td {
      background-color: $color_accent;
    }
  }
}
