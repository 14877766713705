@import "./src/assets/scss/app-variables";

.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.sidebarHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: $color_additional_darken-2;
  padding: 20px 10px;
  min-height: 64px;
}

.sidebar {
  height: 100vh;
  overflow-y: auto;
  width: 65px;
  transition: $transition-base;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-shrink: 0;
  box-shadow: 6px 0 18px rgba(0, 0, 0, 0.06);
  z-index: 700;
  overflow-x: hidden;

  span + span {
    opacity: 0;
    transition: opacity ease-in-out 0.2s;
    white-space: normal;
    visibility: hidden;
    width: 170px;
    z-index: -1;
  }

  &.open {
    width: 260px;

    span + span {
      opacity: 1;
      display: block;
      visibility: visible;
      z-index: 1;
    }
  }
}

.siteLogo {
  max-width: 80px;
  margin-left: 15px;
  width: 100%;
  height: auto;
}

.imageLogo {
  max-width: 80px;
}

.burgerIcon {
  padding: 0;
  margin: 0 14px;
}

.menuWrapper {
  display: block;

  &:not(:last-child) {
    margin-bottom: 20px;
  }
}

.buildVersion {
  width: 200px;
  display: inline-block;
  font-size: $font-size-sm;
  color: $black;
  margin: 30px 0 10px 26px;
}

.belongHeader {
  background-color: $black;

  .burgerIcon > i::before {
    color: $white;
  }
}

.youknoHeader {
  background-color: $color-primary;

  .burgerIcon > i::before {
    color: $white;
  }
}

.accordionHeader {
  margin-left: 15px;
  display: block !important;
}

.accordionLink {
  width: 100%;
  display: flex;
  align-items: center;
  transition: $transition-base;

  &:active,
  &:hover {
    color: $color-primary;
  }
}

.accordion {
  display: block;
  border: none !important;
  box-shadow: none !important;

  &::before {
    display: none;
  }
}
