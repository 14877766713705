.status-label {
  padding: 3px 15px;
  font-size: 12px;
  font-weight: 700;
  margin-right: 5px;
}

.draft-label {
  color: white;
  background-color: $grey_label;
}

.pinned-label {
  padding: 3px;
  border-radius: 5px;
  font-size: 12px;
}
