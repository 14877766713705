$icomoon-font-family: "joblio-icons" !default;
$icomoon-font-path: "./../fonts/icons" !default;
$icon-user: "\e900";
$icon-d-cube: "\e901";
$icon-full: "\e902";
$icon-user1: "\e903";
$icon-support: "\e904";
$icon-activity: "\e905";
$icon-activity-1: "\e906";
$icon-add: "\e907";
$icon-add-circle: "\e908";
$icon-add-square: "\e909";
$icon-add-user: "\e90a";
$icon-alarm: "\e90b";
$icon-apple-negative: "\e90c";
$icon-archive: "\e90d";
$icon-archive-1: "\e90e";
$icon-archive-add: "\e90f";
$icon-archive-book: "\e910";
$icon-archive-minus: "\e911";
$icon-archive-slash: "\e912";
$icon-archive-tick: "\e913";
$icon-arrange-circle: "\e914";
$icon-arrange-circle-2: "\e915";
$icon-arrange-square: "\e916";
$icon-arrange-square-2: "\e917";
$icon-arrow: "\e918";
$icon-arrow-2: "\e919";
$icon-arrow-3: "\e91a";
$icon-arrow-bottom: "\e91b";
$icon-arrow-circle-down: "\e91c";
$icon-arrow-circle-left: "\e91d";
$icon-arrow-circle-right: "\e91e";
$icon-arrow-circle-up: "\e91f";
$icon-arrow-down: "\e920";
$icon-arrow-down-1: "\e921";
$icon-arrow-down-2: "\e922";
$icon-arrow-left: "\e923";
$icon-arrow-left-1: "\e924";
$icon-arrow-left-2: "\e925";
$icon-arrow-left-3: "\e926";
$icon-arrow-right: "\e927";
$icon-arrow-right-1: "\e928";
$icon-arrow-right-2: "\e929";
$icon-arrow-right-3: "\e92a";
$icon-arrow-right-4: "\e92b";
$icon-arrow-square: "\e92c";
$icon-arrow-square-down: "\e92d";
$icon-arrow-square-left: "\e92e";
$icon-arrow-square-up: "\e92f";
$icon-arrow-swap: "\e930";
$icon-arrow-swap-horizontal: "\e931";
$icon-arrow-up: "\e932";
$icon-arrow-up-1: "\e933";
$icon-arrow-up-2: "\e934";
$icon-arrow-up-3: "\e935";
$icon-autobrightness: "\e936";
$icon-award: "\e937";
$icon-back-square: "\e938";
$icon-bag: "\e939";
$icon-bag-2: "\e93a";
$icon-battery-charging: "\e93b";
$icon-battery-disable: "\e93c";
$icon-battery-empty: "\e93d";
$icon-battery-empty-1: "\e93e";
$icon-battery-full: "\e93f";
$icon-bill: "\e940";
$icon-book: "\e941";
$icon-book-1: "\e942";
$icon-bookmark: "\e943";
$icon-bookmark-1: "\e944";
$icon-bookmark-2: "\e945";
$icon-book-saved: "\e946";
$icon-book-square: "\e947";
$icon-box-2: "\e948";
$icon-briefcase: "\e949";
$icon-briefcase-cross: "\e94a";
$icon-briefcase-tick: "\e94b";
$icon-briefcase-timer: "\e94c";
$icon-broom: "\e94d";
$icon-bubble: "\e94e";
$icon-buy: "\e94f";
$icon-cake: "\e950";
$icon-calculator: "\e951";
$icon-calendar: "\e952";
$icon-calendar-1: "\e953";
$icon-calendar-2: "\e954";
$icon-calendar-3: "\e955";
$icon-calendar-add: "\e956";
$icon-calendar-circle: "\e957";
$icon-calendar-edit: "\e958";
$icon-calendar-remove: "\e959";
$icon-calendar-search: "\e95a";
$icon-calendar-tick: "\e95b";
$icon-call: "\e95c";
$icon-call-1: "\e95d";
$icon-call-add: "\e95e";
$icon-call-calling: "\e95f";
$icon-call-incoming: "\e960";
$icon-calling: "\e961";
$icon-call-minus: "\e962";
$icon-call-missed: "\e963";
$icon-call-outgoing: "\e964";
$icon-call-received: "\e965";
$icon-call-remove: "\e966";
$icon-call-silent: "\e967";
$icon-call-slash: "\e968";
$icon-camera: "\e969";
$icon-candle: "\e96a";
$icon-candle-2: "\e96b";
$icon-category: "\e96c";
$icon-category-1: "\e96d";
$icon-category-2: "\e96e";
$icon-cd: "\e96f";
$icon-chart: "\e970";
$icon-chart-1: "\e971";
$icon-chart-2: "\e972";
$icon-chart-3: "\e973";
$icon-chart-4: "\e974";
$icon-chart-21: "\e975";
$icon-chart-fail: "\e976";
$icon-chart-success: "\e977";
$icon-chat: "\e978";
$icon-check: "\e979";
$icon-chrome: "\e97a";
$icon-clipboard: "\e97b";
$icon-clipboard-close: "\e97c";
$icon-clipboard-export: "\e97d";
$icon-clipboard-import: "\e97e";
$icon-clipboard-text: "\e97f";
$icon-clipboard-tick: "\e980";
$icon-clock: "\e981";
$icon-close-circle: "\e982";
$icon-close-square: "\e983";
$icon-clubhouse---negative: "\e984";
$icon-coffee: "\e985";
$icon-computing: "\e986";
$icon-convert: "\e987";
$icon-copyright: "\e988";
$icon-creative-commons: "\e989";
$icon-crown: "\e98a";
$icon-crown-1: "\e98b";
$icon-cup: "\e98c";
$icon-danger: "\e98d";
$icon-danger-1: "\e98e";
$icon-delete: "\e98f";
$icon-device-message: "\e990";
$icon-diagram: "\e991";
$icon-diamonds: "\e992";
$icon-direct: "\e993";
$icon-directbox-default: "\e994";
$icon-directbox-notif: "\e995";
$icon-directbox-receive: "\e996";
$icon-directbox-send: "\e997";
$icon-direct-down: "\e998";
$icon-direct-inbox: "\e999";
$icon-direct-left: "\e99a";
$icon-direct-normal: "\e99b";
$icon-direct-notification: "\e99c";
$icon-direct-right: "\e99d";
$icon-direct-send: "\e99e";
$icon-direct-up: "\e99f";
$icon-discord-negative: "\e9a0";
$icon-discount: "\e9a1";
$icon-discover: "\e9a2";
$icon-discover-1: "\e9a3";
$icon-discovery: "\e9a4";
$icon-dislike: "\e9a5";
$icon-document: "\e9a6";
$icon-document-1: "\e9a7";
$icon-document-cloud: "\e9a8";
$icon-document-copy: "\e9a9";
$icon-document-download: "\e9aa";
$icon-document-favorite: "\e9ab";
$icon-document-filter: "\e9ac";
$icon-document-forward: "\e9ad";
$icon-document-like: "\e9ae";
$icon-document-normal: "\e9af";
$icon-document-previous: "\e9b0";
$icon-document-sketch: "\e9b1";
$icon-document-text: "\e9b2";
$icon-document-text-1: "\e9b3";
$icon-document-upload: "\e9b4";
$icon-download: "\e9b5";
$icon-dribbble-negative: "\e9b6";
$icon-edit: "\e9b7";
$icon-edit-1: "\e9b8";
$icon-edit-2: "\e9b9";
$icon-edit-square: "\e9ba";
$icon-emoji-happy: "\e9bb";
$icon-emoji-normal: "\e9bc";
$icon-emoji-sad: "\e9bd";
$icon-export: "\e9be";
$icon-export-1: "\e9bf";
$icon-export-2: "\e9c0";
$icon-export-3: "\e9c1";
$icon-eye: "\e9c2";
$icon-eye-slash: "\e9c3";
$icon-facebook-negative: "\e9c4";
$icon-favorite-chart: "\e9c5";
$icon-figma-negative: "\e9c6";
$icon-filter: "\e9c7";
$icon-filter-1: "\e9c8";
$icon-filter-2: "\e9c9";
$icon-filter-add: "\e9ca";
$icon-filter-edit: "\e9cb";
$icon-filter-remove: "\e9cc";
$icon-filter-search: "\e9cd";
$icon-filter-square: "\e9ce";
$icon-filter-tick: "\e9cf";
$icon-finger-circle: "\e9d0";
$icon-finger-scan: "\e9d1";
$icon-flag: "\e9d2";
$icon-flag-2: "\e9d3";
$icon-flash: "\e9d4";
$icon-flash-circle: "\e9d5";
$icon-flash-slash: "\e9d6";
$icon-folder: "\e9d7";
$icon-folder-1: "\e9d8";
$icon-folder-2: "\e9d9";
$icon-folder-add: "\e9da";
$icon-folder-cloud: "\e9db";
$icon-folder-cross: "\e9dc";
$icon-folder-favorite: "\e9dd";
$icon-folder-minus: "\e9de";
$icon-folder-open: "\e9df";
$icon-forbidden: "\e9e0";
$icon-forbidden-2: "\e9e1";
$icon-forward-square: "\e9e2";
$icon-frame: "\e9e3";
$icon-frame-1: "\e9e4";
$icon-frame-2: "\e9e5";
$icon-game: "\e9e6";
$icon-ghost: "\e9e7";
$icon-gift: "\e9e8";
$icon-github-negative: "\e9e9";
$icon-glass: "\e9ea";
$icon-global: "\e9eb";
$icon-global-edit: "\e9ec";
$icon-global-refresh: "\e9ed";
$icon-global-search: "\e9ee";
$icon-google---negative: "\e9ef";
$icon-gps: "\e9f0";
$icon-gps-slash: "\e9f1";
$icon-grammarly: "\e9f2";
$icon-graph: "\e9f3";
$icon-graph-1: "\e9f4";
$icon-happyemoji: "\e9f5";
$icon-hashtag: "\e9f6";
$icon-health: "\e9f7";
$icon-heart: "\e9f8";
$icon-heart-1: "\e9f9";
$icon-heart-add: "\e9fa";
$icon-heart-circle: "\e9fb";
$icon-heart-edit: "\e9fc";
$icon-heart-remove: "\e9fd";
$icon-heart-search: "\e9fe";
$icon-heart-slash: "\e9ff";
$icon-heart-tick: "\ea00";
$icon-hide: "\ea01";
$icon-home: "\ea02";
$icon-home-1: "\ea03";
$icon-home-2: "\ea04";
$icon-home-3: "\ea05";
$icon-home-hashtag: "\ea06";
$icon-home-trend-down: "\ea07";
$icon-home-trend-up: "\ea08";
$icon-home-wifi: "\ea09";
$icon-image: "\ea0a";
$icon-image-2: "\ea0b";
$icon-import: "\ea0c";
$icon-import-1: "\ea0d";
$icon-import-2: "\ea0e";
$icon-info-circle: "\ea0f";
$icon-info-circle-1: "\ea10";
$icon-information: "\ea11";
$icon-info-square: "\ea12";
$icon-instagram: "\ea13";
$icon-instagram-negative: "\ea14";
$icon-judge: "\ea15";
$icon-key: "\ea16";
$icon-key-square: "\ea17";
$icon-lamp: "\ea18";
$icon-lamp-charge: "\ea19";
$icon-lamp-on: "\ea1a";
$icon-lamp-slash: "\ea1b";
$icon-level: "\ea1c";
$icon-like: "\ea1d";
$icon-like-1: "\ea1e";
$icon-like-dislike: "\ea1f";
$icon-like-shapes: "\ea20";
$icon-like-tag: "\ea21";
$icon-linkedin-negative: "\ea22";
$icon-location: "\ea23";
$icon-location-1: "\ea24";
$icon-location-add: "\ea25";
$icon-location-cross: "\ea26";
$icon-location-minus: "\ea27";
$icon-location-slash: "\ea28";
$icon-location-tick: "\ea29";
$icon-lock: "\ea2a";
$icon-lock-1: "\ea2b";
$icon-lock-2: "\ea2c";
$icon-lock-circle: "\ea2d";
$icon-lock-slash: "\ea2e";
$icon-login: "\ea2f";
$icon-login-1: "\ea30";
$icon-login-2: "\ea31";
$icon-logout: "\ea32";
$icon-logout-1: "\ea33";
$icon-logout-2: "\ea34";
$icon-lovely: "\ea35";
$icon-magic-star: "\ea36";
$icon-map: "\ea37";
$icon-map-1: "\ea38";
$icon-medal: "\ea39";
$icon-medal-star: "\ea3a";
$icon-medium-negative: "\ea3b";
$icon-menu: "\ea3c";
$icon-menu-1: "\ea3d";
$icon-menu-board: "\ea3e";
$icon-menu-side: "\ea3f";
$icon-message: "\ea40";
$icon-message-1: "\ea41";
$icon-message-2: "\ea42";
$icon-message-add: "\ea43";
$icon-message-add-1: "\ea44";
$icon-message-circle: "\ea45";
$icon-message-edit: "\ea46";
$icon-message-favorite: "\ea47";
$icon-message-minus: "\ea48";
$icon-message-notif: "\ea49";
$icon-message-question: "\ea4a";
$icon-message-remove: "\ea4b";
$icon-messages: "\ea4c";
$icon-messages-1: "\ea4d";
$icon-messages-2: "\ea4e";
$icon-messages-3: "\ea4f";
$icon-message-search: "\ea50";
$icon-message-square: "\ea51";
$icon-message-text: "\ea52";
$icon-message-text-1: "\ea53";
$icon-message-tick: "\ea54";
$icon-message-time: "\ea55";
$icon-milk: "\ea56";
$icon-minus: "\ea57";
$icon-minus-circle: "\ea58";
$icon-minus-square: "\ea59";
$icon-mirror: "\ea5a";
$icon-more: "\ea5b";
$icon-more-2: "\ea5c";
$icon-more-circle: "\ea5d";
$icon-more-circle-1: "\ea5e";
$icon-more-square: "\ea5f";
$icon-more-square-1: "\ea60";
$icon-mouse: "\ea61";
$icon-mouse-circle: "\ea62";
$icon-mouse-square: "\ea63";
$icon-note: "\ea64";
$icon-note-1: "\ea65";
$icon-note-2: "\ea66";
$icon-note-21: "\ea67";
$icon-note-add: "\ea68";
$icon-note-favorite: "\ea69";
$icon-note-remove: "\ea6a";
$icon-note-text: "\ea6b";
$icon-notification: "\ea6c";
$icon-notification-1: "\ea6d";
$icon-notification-2: "\ea6e";
$icon-notification-bing: "\ea6f";
$icon-notification-circle: "\ea70";
$icon-notification-favorite: "\ea71";
$icon-notification-status: "\ea72";
$icon-paper: "\ea73";
$icon-paper-download: "\ea74";
$icon-paper-fail: "\ea75";
$icon-paper-negative: "\ea76";
$icon-paper-plus: "\ea77";
$icon-paper-upload: "\ea78";
$icon-password: "\ea79";
$icon-password-check: "\ea7a";
$icon-people: "\ea7b";
$icon-personalcard: "\ea7c";
$icon-pet: "\ea7d";
$icon-picture-frame: "\ea7e";
$icon-pinterest-negative: "\ea7f";
$icon-play: "\ea80";
$icon-plus: "\ea81";
$icon-presentation-chart: "\ea82";
$icon-profile: "\ea83";
$icon-profile-2-user: "\ea84";
$icon-profile-add: "\ea85";
$icon-profile-circle: "\ea86";
$icon-profile-delete: "\ea87";
$icon-profile-remove: "\ea88";
$icon-profile-tick: "\ea89";
$icon-radar: "\ea8a";
$icon-radar-1: "\ea8b";
$icon-radar-2: "\ea8c";
$icon-ranking: "\ea8d";
$icon-ranking-1: "\ea8e";
$icon-receipt-square: "\ea8f";
$icon-received: "\ea90";
$icon-receive-square: "\ea91";
$icon-receive-square-2: "\ea92";
$icon-reddit-negative: "\ea93";
$icon-redo: "\ea94";
$icon-refresh: "\ea95";
$icon-refresh-2: "\ea96";
$icon-refresh-circle: "\ea97";
$icon-refresh-left-square: "\ea98";
$icon-refresh-right-square: "\ea99";
$icon-refresh-square-2: "\ea9a";
$icon-repeat: "\ea9b";
$icon-repeat-circle: "\ea9c";
$icon-reserve: "\ea9d";
$icon-rotate-left: "\ea9e";
$icon-rotate-right: "\ea9f";
$icon-route-square: "\eaa0";
$icon-routing: "\eaa1";
$icon-routing-2: "\eaa2";
$icon-safe-home: "\eaa3";
$icon-save-2: "\eaa4";
$icon-save-add: "\eaa5";
$icon-save-minus: "\eaa6";
$icon-save-remove: "\eaa7";
$icon-scan: "\eaa8";
$icon-scan-1: "\eaa9";
$icon-scan-barcode: "\eaaa";
$icon-scanner: "\eaab";
$icon-scanning: "\eaac";
$icon-search: "\eaad";
$icon-search-favorite: "\eaae";
$icon-search-favorite-1: "\eaaf";
$icon-search-normal: "\eab0";
$icon-search-normal-1: "\eab1";
$icon-search-status: "\eab2";
$icon-search-status-1: "\eab3";
$icon-search-zoom-in: "\eab4";
$icon-search-zoom-in-1: "\eab5";
$icon-search-zoom-out: "\eab6";
$icon-search-zoom-out-1: "\eab7";
$icon-security: "\eab8";
$icon-security-safe: "\eab9";
$icon-security-time: "\eaba";
$icon-security-user: "\eabb";
$icon-send: "\eabc";
$icon-send-1: "\eabd";
$icon-send-2: "\eabe";
$icon-send-3: "\eabf";
$icon-send-square: "\eac0";
$icon-send-square-2: "\eac1";
$icon-setting: "\eac2";
$icon-setting-2: "\eac3";
$icon-setting-3: "\eac4";
$icon-setting-4: "\eac5";
$icon-setting-5: "\eac6";
$icon-setting-6: "\eac7";
$icon-settings: "\eac8";
$icon-share: "\eac9";
$icon-shield: "\eaca";
$icon-shield-cross: "\eacb";
$icon-shield-done: "\eacc";
$icon-shield-fail: "\eacd";
$icon-shield-search: "\eace";
$icon-shield-slash: "\eacf";
$icon-shield-tick: "\ead0";
$icon-show: "\ead1";
$icon-signal-negative: "\ead2";
$icon-signpost: "\ead3";
$icon-slash: "\ead4";
$icon-slider: "\ead5";
$icon-smart-home: "\ead6";
$icon-smileys: "\ead7";
$icon-sms: "\ead8";
$icon-sms-edit: "\ead9";
$icon-sms-notification: "\eada";
$icon-sms-search: "\eadb";
$icon-sms-star: "\eadc";
$icon-sms-tracking: "\eadd";
$icon-snapchat-negative: "\eade";
$icon-sort: "\eadf";
$icon-sound: "\eae0";
$icon-speedometer: "\eae1";
$icon-spotify-negative: "\eae2";
$icon-star: "\eae3";
$icon-star-1: "\eae4";
$icon-star-2: "\eae5";
$icon-star-slash: "\eae6";
$icon-status: "\eae7";
$icon-status-up: "\eae8";
$icon-sticker: "\eae9";
$icon-stickynote: "\eaea";
$icon-story: "\eaeb";
$icon-swap: "\eaec";
$icon-tag-cross: "\eaed";
$icon-tag-right: "\eaee";
$icon-tag-user: "\eaef";
$icon-task: "\eaf0";
$icon-task-square: "\eaf1";
$icon-teacher: "\eaf2";
$icon-telegram-negative: "\eaf3";
$icon-tick-circle: "\eaf4";
$icon-ticket: "\eaf5";
$icon-ticket-star: "\eaf6";
$icon-tick-square: "\eaf7";
$icon-tiktok-negative: "\eaf8";
$icon-time-circle: "\eaf9";
$icon-timer: "\eafa";
$icon-timer-1: "\eafb";
$icon-timer-pause: "\eafc";
$icon-timer-start: "\eafd";
$icon-time-square: "\eafe";
$icon-toggle-off: "\eaff";
$icon-toggle-off-circle: "\eb00";
$icon-toggle-on: "\eb01";
$icon-toggle-on-circle: "\eb02";
$icon-trash: "\eb03";
$icon-trash-square: "\eb04";
$icon-tree: "\eb05";
$icon-trend-down: "\eb06";
$icon-trend-up: "\eb07";
$icon-triangle: "\eb08";
$icon-tumblr-negative: "\eb09";
$icon-twitter-negative: "\eb0a";
$icon-undo: "\eb0b";
$icon-unlimited: "\eb0c";
$icon-unlock: "\eb0d";
$icon-unlock-1: "\eb0e";
$icon-upload: "\eb0f";
$icon-user2: "\eb10";
$icon-user-add: "\eb11";
$icon-user-circle-add: "\eb12";
$icon-user-edit: "\eb13";
$icon-user-minus: "\eb14";
$icon-user-octagon: "\eb15";
$icon-user-remove: "\eb16";
$icon-user-search: "\eb17";
$icon-user-square: "\eb18";
$icon-user-tag: "\eb19";
$icon-user-tick: "\eb1a";
$icon-verify: "\eb1b";
$icon-video: "\eb1c";
$icon-vk-negative: "\eb1d";
$icon-voice: "\eb1e";
$icon-voice-2: "\eb1f";
$icon-volume-down: "\eb20";
$icon-volume-off: "\eb21";
$icon-volume-up: "\eb22";
$icon-wallet: "\eb23";
$icon-warning-2: "\eb24";
$icon-weight: "\eb25";
$icon-wifi: "\eb26";
$icon-wifi-square: "\eb27";
$icon-work: "\eb28";
$icon-youtube-negative: "\eb29";
