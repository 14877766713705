.history::after {
  content: "history";
}

.archive::after {
  content: "archive";
}

.dashboard::after {
  content: "dashboard";
}

.loyalty::after {
  content: "loyalty";
}

.shield::after {
  content: "shield";
}

.notification_add::after {
  content: "notification_add";
}

.sports_soccer::after {
  content: "sports_soccer";
}

.file_upload::after {
  content: "file_upload";
}
